import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ChatIcon from "../chat-icon";
import ProductPrice from "../product-price";
import ProductLocation from "../product-location";
import ProductButton from "../product-button";
import ShowPriceButton from "../product-button/show-price";
import ClosingIcon from "../closing-icon";
import axios from "../../utils/axios";
import CustomModal from "../../components/custom-modal";
import CustomCard from "../../components/custom-card";
import CustomButton from "../../components/custom-button";
import arrow from "../../assets/arrow.svg";
import "./product-card.scss";
import LoginSignup from "../login-signup";
import { IoChatbubbleOutline } from "react-icons/io5";
import { Button } from "antd";
import { IoMdHeartEmpty } from "react-icons/io";

const SelectProductIcon = (id, profileItem, handleRemoveItem) =>
  profileItem ? (
    <ClosingIcon
      styles={{ position: "absolute", top: "28px", right: "20px" }}
      handleClose={handleRemoveItem}
      id={id}
    />
  ) : (
    <div
      style={{
        display: "inline-block",
        width: "40px",
        height: "40px",
        position: "absolute",
        top: "0",
        right: "0",
        borderRadius: "0 13px",
      }}
    >
      <button
        className="custom-wishlist"
        style={{
          cursor: "pointer",
          color: "#fff",
          border: "none",
          backgroundColor: "transparent",
          outline: "none",
          borderRadius: "0 13px",
          minHeight: "32px",
          display: "flex",
          alignItems: "center",
          marginBottom: "7px",
          float: "right",
          padding: "10px",
        }}
      >
        <span
          style={{
            width: "25px",
            height: "25px",
            background: "#fff 0 0 no-repeat padding-box",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <IoMdHeartEmpty
            style={{
              color: "black",
              width: "15px",
              height: "15px",
              display: "inline-block",
              fontSize: "1.5rem",
              transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              flexShrink: 0,
              userSelect: "none",
            }}
          />
        </span>
      </button>
    </div>
  );

const CardLabel = (status) => {
  let color, bg;
  color = "#FFFFFF";
  switch (status) {
    case "APPROVED":
      bg = "#178BFC";
      break;
    case "SOLDOUT":
      bg = "#FFFFFF";
      color = "#0E0E0E";
      break;
    case "RESERVED":
      bg = "#707070";
      break;
    case "REJECTED":
      bg = "red";
      break;
    default:
      bg = "";
      color = "#1d1d1f";
      break;
  }
  return (
    <div
      className="profile-card-label"
      style={{ color: color, background: bg }}
    >
      {status}
    </div>
  );
};

const HiddenProduct = (productDetails) => {
  return (
    <div className="hidden-product">
      <div className="waiting-approval-box">
        <h5>Waiting for approval</h5>
      </div>
      <div className="view-item" onClick={productDetails}>
        View item <img src={arrow} alt="arrow" />
      </div>
    </div>
  );
};

const ProductCard = (props) => {
  const {
    id,
    name,
    price,
    currency,
    prodLocation,
    img,
    mediaType,
    state,
    status,
    handleChangeStatus,
    requestVIPServices,
    attributes,
    styles,
    history,
    profile,
    profileItem,
    handleRemoveItem,
    isSponsored,
    isRecommendedProduct,
  } = props;
  const productDetails = () => {
    history.push(`/products-details/${id}`);
  };

  // buy now card
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBuy = () => {
    setDone(true);
    axios.post("BuyItem", { itemId: id });
  };
  const handleShowPrice = () => {
    if (axios.defaults.headers.common["Authentication"] !== null) {
      setShowPrice(true);
    } else {
      handleOpenLogin();
    }
  };
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  let buttonText = state ? "In your Wishlist" : "Add to Wishlist";
  if (profile) buttonText = "Buy Now";
  return (
    <div
      className={`product-card-container ${
        isRecommendedProduct && "custom-product__card-container"
      }`}
    >
      {axios.defaults.headers.common["Authentication"] == null && (
        <CustomModal
          open={openLogin}
          classes="login-modal"
          closeModal={handleCloseLogin}
        >
          <LoginSignup handleCloseLogin={handleCloseLogin} />
        </CustomModal>
      )}
      <CustomModal classes="buy-modal" closeModal={handleClose} open={open}>
        <CustomCard classes="buy-card">
          {!done ? (
            <>
              {" "}
              <h1>BUY NOW</h1>
              <h3>
                By pressing confirm you agree to our{" "}
                <Link to="/terms-conditions" target="_blank">
                  Terms & Conditions
                </Link>
              </h3>
              <CustomButton
                type="button"
                styles={{
                  display: "block",
                  height: "40px",
                  width: "150px",
                  margin: "auto",
                  marginTop: "10px",
                }}
                onClick={handleBuy}
              >
                Confirm
              </CustomButton>{" "}
            </>
          ) : (
            <>
              <h1>Congrates</h1>
              <h3>Connected VIP Assistants will contact you soon</h3>{" "}
              <CustomButton
                type="button"
                styles={{
                  display: "block",
                  height: "40px",
                  width: "150px",
                  margin: "auto",
                  marginTop: "10px",
                }}
                onClick={handleClose}
              >
                Close
              </CustomButton>
            </>
          )}
        </CustomCard>
      </CustomModal>
      {status === "Pending" ? HiddenProduct(productDetails) : null}
      {mediaType.type === "VIDEO" ? (
        <video
          muted
          controls
          autoPlay
          className="product-img"
          style={{ cursor: "pointer", ...styles }}
          onClick={productDetails}
          alt="product"
        >
          <source src={img} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      ) : (
        <img
          className="product-img"
          style={{ cursor: "pointer", ...styles }}
          onClick={productDetails}
          alt="product"
          src={img}
        />
      )}
      {SelectProductIcon(id, profileItem, handleRemoveItem)}

      <div className="product-data-container">
        {profileItem && CardLabel(status)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="product-data">
            <h5
              onClick={productDetails}
              style={{
                cursor: "pointer",
                marginTop: 2,
                marginBottom: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {name}{" "}
              {requestVIPServices ? (
                <VerifiedUserIcon
                  fontSize="small"
                  style={{ color: "#1070C9", marginLeft: "5px" }}
                />
              ) : null}
            </h5>
            {!isSponsored && <ProductLocation prodLocation={prodLocation} />}
            {attributes ? (
              <div className="attribute-container">
                {attributes.slice(0, 2).map((attr) => (
                  <div key={Object.keys(attr)}>
                    <span className="attribute-name">
                      {Object.keys(attr)}:{" "}
                    </span>
                    <span className="attribute-value">
                      {Object.values(attr)}
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
            {showPrice && (
              <ProductPrice
                isSponsored={isSponsored}
                price={price}
                currency={currency}
              />
            )}
          </div>
          <div className="product-options">
            {!showPrice && (
              <ShowPriceButton
                handleClick={handleShowPrice}
                id={id}
                status={state}
                item={props}
              >
                {"Show Price"}
              </ShowPriceButton>
            )}

            <div
              className="product-button-container"
              style={{ marginRight: "5px" }}
            >
              <button className="product-button">
                <span className="heart-circle" style={{ color: "black" }}>
                  <IoChatbubbleOutline />
                </span>

                <span>Message Us</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductCard);

// {profileItem || isSponsored ? null : (
//   <ProductButton
//     handleClick={profile ? handleOpen : handleChangeStatus}
//     id={id}
//     status={state}
//     item={props}
//   >
//     <span className="heart-circle" style={{ color: "black" }}>
//       <IoChatbubbleOutline />
//     </span>

//     <span>Message us</span>
//   </ProductButton>
// )}

// <ChatIcon
//       imgW={10}
//       imgH={10}
//       styles={{
//         width: "20px",
//         height: "20px",
//         position: "absolute",
//         top: 12,
//         right: 14,
//       }}
//     />
