import React from "react";

import "./product-price.scss";

const ProductPrice = ({ price, styles, currency, isSponsored }) => {
  return (
    <h5 className="product-price" style={{ ...styles }}>
      {isSponsored ? (
        "Sponsored"
      ) : (
        <>
          {price?.toLocaleString()}
          <sup className="currency">{currency || "EGP"}</sup>
        </>
      )}
    </h5>
  );
};

export default ProductPrice;
