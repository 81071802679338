import React, { useEffect, useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import "./header-menu.scss";
import logo from "../../assets/small-logo.png";
import home from "../../assets/home.svg";
import car from "../../assets/header-car.svg";
import watch from "../../assets/header-watch.svg";
import businessman from "../../assets/businessman.svg";
import searchIcon from "../../assets/search.svg";
import arrow from "../../assets/arrow.svg";
import ClosingIcon from "../closing-icon";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/selector";
import { connect, useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import { user_logout } from "../../redux/user/actions";
import { useHistory, useLocation } from "react-router";
import axios from "../../utils/axios";

const HeaderMenu = ({ handleClose, handleOpenLogin, user }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(undefined);
  const [searchList, setSearchList] = useState([]);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname === "/login" && !user) handleOpenLogin();
  }, [user, pathname, handleOpenLogin]);
  const handleSearch = e => {
    const val = e.target.value;
    setSearch(val);
  };
  const submitSearch = () => {
    handleClose();
    history.push("/products/" + search);
  };
  const selectSearch = res => {
    handleClose();
    history.push("/products/" + res);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios
        .post("AutoCompleteSearch", {
          term: search,
          category: null,
        })
        .then(res => {
          setSearchList(res.data?.result?.value || []);
        });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  return (
    <div className="header-menu-container">
      <ClosingIcon styles={{ position: "absolute", top: "58px", right: "16px" }} handleClose={handleClose} />
      <div className="search-icon">
        <TextField
          id="input-with-icon-textfield"
          className="menu-search"
          placeholder="search..."
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img onClick={submitSearch} alt="search icon" src={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        {searchList.length ? (
          <ul>
            {searchList.map(ele => (
              <li onClick={() => selectSearch(ele.document.Title)}>{ele.document.Title}</li>
            ))}
          </ul>
        ) : null}
      </div>
      <div className="list-container">
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button component="a" href="/">
            <ListItemIcon>
              <img alt="Home Page" src={logo} />
            </ListItemIcon>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button component="a" href="/real-estate">
            <ListItemIcon>
              <img alt="Real Estate" src={home} />
            </ListItemIcon>
            <ListItemText primary="Real Estate" />
          </ListItem>
          <ListItem button component="a" href="/automotive">
            <ListItemIcon>
              <img alt="Automotive" src={car} />
            </ListItemIcon>
            <ListItemText primary="Automotive" />
          </ListItem>
          <ListItem button component="a" href="/life-style">
            <ListItemIcon>
              <img alt="Life Style" src={watch} />
            </ListItemIcon>
            <ListItemText primary="Life Style" />
          </ListItem>
          <ListItem button component="a" href="/concierge">
            <ListItemIcon>
              <img alt="Premium Advertisement" src={businessman} />
            </ListItemIcon>
            <ListItemText primary="Premium Advertisement" />
          </ListItem>
          {user ? (
            <>
              <Divider className="divider" />
              <ListItem className="internal-pages" button component="a" href="/add-item">
                <ListItemText primary="Add Item" />
              </ListItem>
              <ListItem className="internal-pages" button component="a" href="/profile/wishlist">
                <ListItemText primary="My Wishlist" />
              </ListItem>
              <ListItem className="internal-pages" button component="a" href="/profile/items">
                <ListItemText primary="My Items" />
              </ListItem>
              <ListItem className="internal-pages" button component="a" href="/profile/info">
                <ListItemText primary="My Profile" />
              </ListItem>
              <ListItem className="internal-pages" button component="a" href="/profile/settings">
                <ListItemText primary="Profile settings" />
              </ListItem>
              <ListItem
                className="internal-pages"
                button
                component="a"
                href="/"
                onClick={() => {
                  dispatch(user_logout());
                }}>
                <ListItemText primary="Logout" />
              </ListItem>
            </>
          ) : null}
        </List>
      </div>
      {user ? null : (
        <div className="user-part" onClick={handleOpenLogin}>
          Login /Signup <img src={arrow} alt="arrow" />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUserData,
});
export default connect(mapStateToProps)(HeaderMenu);
