import { WIDTH_CHANGED } from "./types";
const initialState = {
  width: typeof window === "object" ? window.innerWidth : null,
};

const windowReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIDTH_CHANGED:
      return { ...state, width: action.payload.width };

    default:
      return state;
  }
};

export default windowReducer;
