import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./highlights.scss";
import HighlightsCard from "../highlights-card";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectWidth } from "../../redux/window-size/selector";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Highlights = ({ highlights, width }) => {
  return (
    <div className="home-highlights-container">
      <h1 style={{ color: "#E6E8FA" }}>HIGHLIGHTS</h1>
      <Carousel
        showDots={false}
        transitionDuration={500}
        arrows={true}
        infinite={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlay={width <= 768 ? true : false}
        responsive={responsive}
        draggable={false}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px">
        {highlights.map(({ partitionKey, media, title, state }) => (
          <HighlightsCard
            key={partitionKey}
            id={partitionKey}
            img={process.env.REACT_APP_API_IMAGE_URL + media[0].link}
            name={title}
            state={state}
          />
        ))}
      </Carousel>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  width: selectWidth,
});

export default connect(mapStateToProps)(Highlights);
