import React from "react";
import Slider from "@material-ui/core/Slider";
import CustomButton from "../custom-button";
import CustomInput from "../custom-input";

const valuetext = (value) => {
  return `${value}°C`;
};
const valueFromat = (value) => {
  return `${value / 1000000}m`;
};
const PriceSlider = ({ handleChangePrice, handleClick, price }) => {
  const onChangeCommitted = (event, value) => {};
  const handleInputChange = (e, type) => {
    type === 1
      ? handleChangePrice(e, [e.target.value, price[1]])
      : handleChangePrice(e, [price[0], e.target.value]);
  };
  return (
    <div style={{ paddingLeft: "10px", marginTop: "5px" }}>
      <div className="price-input-container">
        <CustomInput
          onChange={(e) => handleInputChange(e, 1)}
          value={price[0]}
          lable="from"
          type="number"
        />
        <CustomInput
          onChange={(e) => handleInputChange(e, 2)}
          value={price[1]}
          lable="to"
          type="number"
        />
      </div>
      <div>
        <Slider
          value={price}
          onChange={handleChangePrice}
          onChangeCommitted={onChangeCommitted}
          valueLabelDisplay="auto"
          valueLabelFormat={valueFromat}
          max={100000000}
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          step={1000000}
          aria-valuetext={"test"}
          className="price-slider"
        />
      </div>
      <div style={{ width: "95%" }}>
        <CustomButton
          type="button"
          styles={{
            background: "#1070C9",
            color: "white",
            width: "100%",
            height: "35px",
          }}
          handleClick={handleClick}
        >
          Apply
        </CustomButton>
      </div>
    </div>
  );
};

export default PriceSlider;
