import React from "react";

import "./product-ads-card.scss";

const ProductAdsCard = ({ ads }) => {
  return (
    <div className="product-ads-card">
      <h4>Premium Ads</h4>
      <video controls autoPlay muted>
        <source
          src={process.env.REACT_APP_API_IMAGE_URL + ads[0].media[0].link}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default ProductAdsCard;
