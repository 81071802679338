import React from "react";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import "./home-slider-controller.scss";

const HomeSliderController = ({
  handleBack,
  handleNext,
  handlePasue,
  maxSteps,
  activeStep,
  pause
}) => {
  return (
    <div className="slider-controller">
      <Button onClick={handleBack} disabled={activeStep === 0}>
        <KeyboardArrowLeft
          className={`slider-icons ${activeStep === 0 ? "disabled-icon" : ""}`}
        />
      </Button>
      <Button onClick={handlePasue}>
        {pause ? (
          <PlayArrowIcon className="slider-icons" />
        ) : (
          <PauseIcon className="slider-icons" />
        )}
      </Button>
      <Button onClick={handleNext} disabled={activeStep === maxSteps - 1}>
        <KeyboardArrowRight
          className={`slider-icons ${
            activeStep === maxSteps - 1 ? "disabled-icon" : ""
          }`}
        />
      </Button>
    </div>
  );
};

export default HomeSliderController;
