import React from "react";

import "./custom-modal.scss";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grow from "@material-ui/core/Grow";
import Zoom from "@material-ui/core/Zoom";

const CustomModal = ({ children, closeModal, classes, open }) => {
  return (
    <Modal
      className={`custom-modal ${classes}`}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="custom-modal"
      aria-describedby="modal for froms"
    >
      <Grow in={open} {...(open ? { timeout: 2000 } : {})}>
        <Zoom in={true} style={{ transitionDelay: "500ms" }}>
          {children}
        </Zoom>
      </Grow>
    </Modal>
  );
};

export default CustomModal;
