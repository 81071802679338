import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useHistory } from "react-router-dom";
import { Form, Upload } from "antd";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, Grid } from "@material-ui/core";

import PrevPageLinks from "../../components/prev-page-links";
import { selectUserData } from "../../redux/user/selector";
import CustomSelect from "../../components/custom-select";
import CustomButton from "../../components/custom-button";
import CustomModal from "../../components/custom-modal";
import CustomCard from "../../components/custom-card";
import ImagesBox from "../../components/images-box";
import CloseIcon from "../../components/closing-icon";

import CustomInput from "../../components/custom-input";
import { selectWidth } from "../../redux/window-size/selector";
import { Colors } from "../../constants";

import axios from "../../utils/axios";
import cameraImg from "../../assets/camera.svg";
import phoneIcon from "../../assets/phone-icon.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ShowNotification from "../../components/notification";

import "./add-item.scss";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const AddItem = ({ user, width }) => {
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [done, setDone] = useState(false);
  const [state, setState] = useState({
    category: null,
    title: null,
    price: null,
    location: null,
    description: null,
  });
  const [errors, setErrors] = useState({
    category: null,
    title: null,
    price: null,
    location: null,
    description: null,
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (value) {
      setErrors((state) => ({ ...state, [name]: null }));
    }
    setState((state) => ({ ...state, [name]: value }));
  };
  useEffect(() => {
    axios.get("GetLocations").then((res) => {
      setLocations(res.data.result);
    });
    axios.get("GetCategories").then((res) => {
      setCategories(
        res.data.result.map((ele) => {
          return { ...ele, partitionKey: ele.rowKey };
        })
      );
    });
  }, []);
  const imageChange = ({ file }) => {
    if (file.response)
      setImgs((state) => [...state, file.response.result.name]);
  };

  const onFinish = (values) => {
    const err = {};
    let isError = false;
    if (!state?.description?.trim()) {
      err.description = "Description is required";
      isError = true;
    } else {
      err.description = null;
    }
    if (!state?.title?.trim()) {
      err.title = "Title is required";
      isError = true;
    } else {
      err.title = null;
    }
    if (!state.price) {
      err.price = "Price is required";
      isError = true;
    } else if (state.price <= 0) {
      err.price = "Price is not valid";
      isError = true;
    } else {
      err.price = null;
    }

    if (!state.location) {
      err.location = "Location is required";
      isError = true;
    } else {
      err.location = null;
    }
    if (!state.category) {
      err.category = "Category is required";
      isError = true;
    } else {
      err.category = null;
    }
    if (isError) {
      setErrors(err);
      return;
    }
    axios
      .post("addItem", {
        ...state,
        price: parseInt(state.price),
        thumbnail: imgs[0],
        media: imgs.map((ele) => ({ type: "IMAGE", link: ele })),
        priceRequest: false,
        cameraRequest: false,
        attributes: {},
      })
      .then((res) => {
        setDone(true);
      });
  };
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDone = () => {
    setDone(false);
    history.push("/");
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleRemoveImg = (name) => {
    setImgs((state) => state.filter((ele) => ele !== name));
  };

  // notification
  const [openMsg, setOpenMsg] = useState(false);
  const handleCloseMsg = () => {
    setOpenMsg(false);
  };
  const handleOpenMsg = () => {
    setOpenMsg(true);
  };
  const sendHelp = () => {
    axios
      .post(`/Assistance/addItem`, {
        name: user.name,
        phone: user.phone,
        timeSlot: "asap",
      })
      .then((res) => {
        if (res.data.success) handleOpenMsg(true);
      });
  };
  return (
    <div className="add-item">
      <ShowNotification
        open={openMsg}
        msg={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon size={22} className="success-icon" />
            Request sent succefully our agent will call you as soon as posible
          </div>
        }
        handleCloseMsg={handleCloseMsg}
        handleOpenMsg={handleOpenMsg}
      />
      <CustomModal
        open={done}
        classes="add-item-modal"
        closeModal={handleCloseDone}
      >
        <CustomCard classes="add-item-card">
          <h1>Created successfully</h1>
          <p>
            Connected agents will review your post within 24 hours, please be
            advised that your post will not be published unless it meets
            Connected Services terms and conditions.
          </p>
          <CustomButton
            type="button"
            styles={{
              display: "block",
              height: "40px",
              width: "150px",
              marginTop: "10px",
              marginLeft: "auto",
              color: "white",
            }}
            onClick={handleCloseDone}
          >
            Ok
          </CustomButton>
        </CustomCard>
      </CustomModal>
      <CustomModal
        open={open}
        classes="add-item-modal"
        closeModal={handleClose}
      >
        <CustomCard classes="add-item-card">
          <div className="card-imgs-div">
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={6} xs={6}>
                <Upload
                  name="logo"
                  action={
                    process.env.REACT_APP_API_BASE_URL + "UploadImage/IMAGE"
                  }
                  headers={{ Authentication: user.token }}
                  listType="picture"
                  onChange={imageChange}
                >
                  <div className="image-container">
                    <img src={cameraImg} alt="avatar" />
                  </div>
                </Upload>
              </Grid>
              {imgs.map((img) => (
                <Grid item lg={3} md={4} sm={6} xs={6}>
                  <div className="modal-img-container">
                    <CloseIcon handleClose={() => handleRemoveImg(img)} />
                    <img
                      className="modal-img"
                      src={process.env.REACT_APP_API_IMAGE_URL + img}
                      alt="item img"
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div
            style={{ color: "#178BFC", float: "right", cursor: "pointer" }}
            onClick={handleClose}
          >
            {"Back >"}
          </div>
        </CustomCard>
      </CustomModal>
      <PrevPageLinks>
        <Link className="prev" to="/">
          Home
        </Link>{" "}
        /{" "}
        <Link className="current" to="/about-us">
          Add item
        </Link>
      </PrevPageLinks>
      <h2>ADD ITEM</h2>
      <div className="add-item-form">
        <Form {...formItemLayout} onFinish={onFinish}>
          <Grid container spacing={1}>
            {/* images uploader */}
            <Grid item container spacing={1} lg={2} md={2} xs={12} sm={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={6}
                xs={6}
                style={{ maxHeight: "198.4px", maxWidth: "149px" }}
              >
                <Upload
                  name="logo"
                  action={
                    process.env.REACT_APP_API_BASE_URL + "UploadImage/IMAGE"
                  }
                  headers={{ Authentication: user.token }}
                  listType="picture"
                  onChange={imageChange}
                >
                  <div className="image-container">
                    <img src={cameraImg} alt="avatar" />
                  </div>
                </Upload>
                <div className="add-photo-text">Add Photo</div>
              </Grid>

              <ImagesBox imgs={imgs} openImgs={handleOpen} />
            </Grid>
            {/* form input  */}
            <Grid item container lg={10} md={10} xs={12} sm={12} spacing={2}>
              {/* category */}
              <Grid item lg={4} md={4} xs={6} sm={6}>
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{
                      minWidth: "100%",
                      color: Colors.secondary,
                      height: "48px",
                    }}
                  >
                    <CustomSelect
                      options={categories}
                      label="Category"
                      value={state.category}
                      name="category"
                      // handleChange={handleCategory}
                      handleChange={handleChange}
                    />
                  </FormControl>
                  <span className="error-msg">{errors.category}</span>
                </Form.Item>
              </Grid>
              {/* title  */}
              <Grid item lg={4} md={4} xs={6} sm={6}>
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: Colors.secondary,
                      height: "48px",
                    }}
                  >
                    <CustomInput
                      label="Title..."
                      type="text"
                      name="title"
                      value={state.title}
                      styles={{ height: "30px" }}
                      handleChange={handleChange}
                    />
                  </FormControl>
                  <span className="error-msg">{errors.title}</span>
                </Form.Item>
              </Grid>
              {/* location */}
              <Grid item lg={4} md={4} xs={6} sm={6}>
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{
                      minWidth: "100%",
                      color: Colors.primary,
                      height: "48px",
                    }}
                  >
                    <CustomSelect
                      options={locations}
                      label="Location"
                      value={state.location}
                      name="location"
                      handleChange={handleChange}
                    />
                  </FormControl>
                  <span className="error-msg">{errors.location}</span>
                </Form.Item>
              </Grid>
              {/* price  */}
              <Grid
                item
                lg={4}
                md={4}
                xs={6}
                sm={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: Colors.secondary,
                      height: "48px",
                    }}
                    name="price"
                  >
                    <CustomInput
                      label="Price in (EGP)"
                      type="number"
                      name="price"
                      value={state.price}
                      styles={{ height: "30px" }}
                      handleChange={handleChange}
                    />
                  </FormControl>
                  <span className="error-msg">{errors.price}</span>
                </Form.Item>
                {width > 768 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      required
                      onChange={() => {}}
                      style={{ marginRight: "12px" }}
                    />
                    <span style={{ marginRight: "3px" }}>I Agree to </span>
                    <Link
                      className="terms-link"
                      to="/terms-conditions"
                      target="blank"
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                ) : null}
              </Grid>
              {/* description */}
              <Grid item lg={8} md={8} xs={12} sm={12}>
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{
                      minWidth: "100%",
                      color: Colors.primary,
                    }}
                    name="description"
                  >
                    <textarea
                      className="description"
                      placeholder="Description"
                      name="description"
                      value={state.description}
                      onChange={handleChange}
                    ></textarea>
                  </FormControl>
                  <span className="error-msg">{errors.description}</span>
                </Form.Item>
              </Grid>
              {width <= 768 ? (
                <Grid
                  item
                  lg={8}
                  md={8}
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    required
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    style={{ marginRight: "12px" }}
                  />
                  <span style={{ marginRight: "3px" }}>I Agree to </span>
                  <Link
                    className="terms-link"
                    to="/terms-conditions"
                    target="blank"
                  >
                    Terms & Conditions
                  </Link>
                </Grid>
              ) : null}
              {/* add item button  */}
              <Grid item lg={6} md={6} xs={6} sm={6}>
                <Form.Item>
                  <CustomButton type="submit" htmlType="submit">
                    Add Item
                  </CustomButton>
                </Form.Item>
              </Grid>
              {/* request help */}
              <Grid item lg={6} md={6} xs={6} sm={6}>
                <Form.Item>
                  <CustomButton
                    styles={{
                      background: "#1D1D1F",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    type="button"
                    htmlType="submit"
                    handleClick={sendHelp}
                  >
                    <img className="phone-icon" src={phoneIcon} alt="phone" />
                    Request Help
                  </CustomButton>
                </Form.Item>
              </Grid>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <div className="last-text">
                  *Connected agents will review your post within 24 hours,
                  please be advised that you post will not be published unless
                  it meets Connected Services terms and conditions
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUserData,
  width: selectWidth,
});
export default connect(mapStateToProps)(AddItem);
