import React from "react";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaClock } from "react-icons/fa";
import styles from "./NewContactUs.module.scss";
import Header from "../../components/header";
import { Container } from "@material-ui/core";

const NewContactUs = () => {
  return (
    <div>
      <Header />
      <Container>
        <div className={styles.contactUs}>
          <div className={styles.contactUsInfo}>
            <p>Home / Contact us</p>
            <h1>CONTACT US</h1>
            <p className={styles.subheading}>
              For more information, please contact with us
            </p>
            <div className={styles.details}>
              <p>
                <FaMapMarkerAlt /> 53 Nozha street, Nasr city
              </p>
              <p>
                <FaEnvelope /> sales@connectedapp.com
              </p>
              <p>
                <FaPhone /> +201027651515
              </p>
              <p>
                <FaPhone /> +20222900004
              </p>
              <p>
                <FaClock /> Working Hours
              </p>
              <p>Sun - Thu</p>
              <p>09:00 AM - 05:00 PM</p>
            </div>
          </div>
          <div className={styles.contactUsForm}>
            <h1>CONTACT FORM</h1>
            <form>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <textarea placeholder="Message"></textarea>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewContactUs;
