import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import PrevPageLinks from "../../components/prev-page-links";
import ProductsHeader from "../../components/products-header";

import { getSpacing } from "../../utils/helperFunctions";
import "./products.scss";
import FilterList from "../../components/filters-list";
import ProductCard from "../../components/product-card";
import axios from "../../utils/axios";
import { Box, Container } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { logAnalyticsEvent } from "../../utils/analyticsLogs";
import Header from "../../components/header";

const Products = ({ type, link, width, locationParam, searchTerm }) => {
  const [sortBy, setSortBy] = useState(null);
  const [page, setPage] = useState(1);
  const [firstTime, setFirstTime] = useState(true);
  const [total, setTotal] = useState(0);
  const [category, setCategory] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [chosedFilters, setChosedFilters] = useState({});
  const [filters, setFilters] = useState([]);
  const [products, setProducts] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [price, setPrice] = useState({ priceMin: 0, priceMax: 100000000 });
  const mobile = width <= 425;
  const tablet = width <= 768;
  const spacing = getSpacing(mobile, tablet);
  const getCategories = async () => {
    const res = await axios.get("GetCategories");
    const categorySelected = res.data.result.find((ele) => {
      return ele.name === type;
    });
    setAttributes(categorySelected.attributes);
    setCategory(categorySelected.rowKey);
  };
  useEffect(() => {
    if (!searchTerm) {
      getCategories();
      setFirstTime(true);
    }
  }, [type]);
  useEffect(() => {
    let sorting;
    switch (sortBy) {
      case "1":
        sorting = { field: "Price", order: "asc" };
        break;
      case "2":
        sorting = { field: "Price", order: "desc" };
        break;
      case "3":
        sorting = { field: "Title", order: "asc" };
        break;
      case "4":
        sorting = { field: "Title", order: "desc" };
        break;
      default:
        sorting = null;
    }
    if (category || searchTerm) {
      setProducts([null, null, null, null, null, null, null, null, null]);
      const objectFilter = {};
      if (chosedFilters["attribute0"]) {
        objectFilter.location = [...chosedFilters["attribute0"], locationParam];
      }

      axios
        .post("SearchItems", {
          category: category,
          term: searchTerm,
          orderBy: sorting,
          pageNumber: page - 1,
          itemsPerPage: 9,
          ...objectFilter,
          ...chosedFilters,
          ...price,
        })
        .then(async (res) => {
          const locations = await axios.get("GetLocations");
          const wishlist = await (
            await axios.post("GetWishlistedItems")
          )?.data?.result?.items;
          setTotal(res.data.result["@odata.count"]);
          setProducts(
            res.data.result.value.map((prod) => {
              const prodLoc = locations.data.result.find(
                (ele) => ele.partitionKey === prod.document.location
              ).name;
              prod.document.location = prodLoc;
              const existEle = wishlist?.find(
                (item) => item.partitionKey === prod.document.itemId
              );
              prod.document.state = existEle ? true : false;
              return prod;
            })
          );
          if (firstTime) {
            let filtersList = [
              {
                title: "Locations",
                list: locations.data.result,
                closing: true,
                opened: false,
              },
            ];
            if (!searchTerm)
              for (let i = 0; i < attributes?.length; ++i) {
                if (
                  attributes[i] === "Amenities" ||
                  attributes[i] === "Option list" ||
                  res.data.result["@search.facets"]["attribute" + (i + 1)]
                    ?.length === 0
                )
                  continue;
                const objectEx = {
                  title: attributes[i],
                  list: res.data.result["@search.facets"][
                    "attribute" + (i + 1)
                  ]?.map((ele) => ({
                    name: ele.value,
                    partitionKey: ele.value,
                  })),
                  position: i + 1,
                  closing: true,
                  opened: false,
                };
                filtersList.push(objectEx);
              }
            setFilters(filtersList);
            setFirstTime(false);
          }
        });
    }
  }, [page, sortBy, category, chosedFilters, price, searchTerm]);
  const handleSortBy = (e) => {
    setSortBy(e.target.value);
  };
  const handleChangeStatus = (id) => {
    let newState = false;
    setProducts(
      products.map((ele) => {
        if (ele.document.itemId === id) {
          logAnalyticsEvent("AddToWishlist", { ...ele.document });
          newState = !ele.document.state;
          ele.document.state = !ele.document.state;
        }
        return ele;
      })
    );
    const type = newState ? "AddItemToWishlist" : "RemoveItemFromWishlist";
    axios
      .post(type, { itemId: id })
      .then((res) => {})
      .catch((err) => {
        setProducts(
          products.map((ele) => {
            if (ele.document.itemId === id) {
              newState = !ele.document.state;
              ele.document.state = !ele.document.state;
            }
            return ele;
          })
        );
      });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleFilterPrice = (v1, v2) => {
    setPrice({ priceMin: v1, priceMax: v2 });
  };
  const handleSelect = (e, position, title) => {
    const fils = chosedFilters;
    if (!fils["attribute" + position]) fils["attribute" + position] = [];
    if (e.target.checked) {
      const newArr = [...fils["attribute" + position], title];
      fils["attribute" + position] = newArr;
    } else {
      const newArr = fils["attribute" + position].filter(
        (ele) => ele !== title
      );
      fils["attribute" + position] = newArr;
    }
    setPage(1);
    setChosedFilters({ ...fils });
  };
  return (
    <div>
      <Header />
      <Container>
        <div className="products-container">
          <PrevPageLinks>
            <Link className="prev" to="/">
              Home
            </Link>{" "}
            /{" "}
            <Link className="current" to={link}>
              Search result
            </Link>
          </PrevPageLinks>
          <ProductsHeader
            handleFilterPrice={handleFilterPrice}
            handleSelect={handleSelect}
            filtersList={filters}
            handleSortBy={handleSortBy}
            sortBy={sortBy}
          />
          <Grid container spacing={spacing}>
            <Grid item lg={3} md={4} sm={4}>
              {mobile ? null : (
                <FilterList
                  handleFilterPrice={handleFilterPrice}
                  handleSelect={handleSelect}
                  filtersList={filters}
                />
              )}
            </Grid>
            <Grid
              item
              style={{ paddingRight: 0 }}
              container
              lg={9}
              md={8}
              sm={8}
              spacing={3}
            >
              {products.length ? (
                products.map((item) =>
                  item ? (
                    <Grid
                      key={item.document.itemId}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                    >
                      <ProductCard
                        requestVIPServices={item.document.requestVIPServices}
                        name={item.document.title}
                        currency={item.document.currency}
                        isSponsored={item.document.sponsoredBy === "true"}
                        price={item.document.price}
                        prodLocation={item.document.location}
                        state={item.document.state}
                        img={
                          process.env.REACT_APP_API_IMAGE_URL +
                          item.document.media[0].link
                        }
                        mediaType={item.document.media[0]}
                        status={item.document.status}
                        id={item.document.itemId}
                        handleChangeStatus={handleChangeStatus}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box pt={0.5}>
                        <Skeleton variant="rect" height={196} />
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Grid>
                  )
                )
              ) : (
                <h4>No results found</h4>
              )}
              {total >= 9 ? (
                <div className="pagination-container">
                  <Pagination
                    className="pagination"
                    count={Math.ceil(total / 9)}
                    page={page}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ windowReducer: { width } }) => ({
  width,
});
export default connect(mapStateToProps)(Products);
