import React from "react";

import "./closing-icon.scss";
import close from "../../assets/close.svg";
const ClosingIcon = ({ styles, handleClose, id }) => (
  <div
    className="closing-icon"
    onClick={() => handleClose(id)}
    style={{ ...styles }}
  >
    <img alt="close" src={close} />
  </div>
);
export default ClosingIcon;
