import React, { useEffect, useState } from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import MaterialCloseIcon from "@material-ui/icons/Close";

const ShowNotification = ({ msg, open, handleCloseMsg, handleOpenMsg }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleCloseMsg}
      message={msg}
      key={"top" + "center"}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseMsg}
          >
            <MaterialCloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};
export default ShowNotification;
