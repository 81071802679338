import React from "react";

import "./footer-bar.scss";
import { Container } from "@material-ui/core";

import facebook from "../../assets/facebook.svg";
import insta from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
const FooterBar = () => {
  return (
    <div className="footer-bar-container">
      <Container className="bar-content">
        <p style={{ textAlign: "center" }}>All Rights Reserved @ 2020</p>
      </Container>
    </div>
  );
};

export default FooterBar;

// <div>
//           <a target="_blank" href="https://www.facebook.com/connectedvip/" className="social-media">
//             <img alt="facebook" src={facebook} />
//           </a>
//           <a target="_blank" href="https://www.instagram.com/connectedvip/" className="social-media">
//             <img alt="instagram" src={insta} />
//           </a>
//           {/* <a target="_blank" href="/" className="social-media">
//             <img alt="twitter" src={twitter} />
//           </a> */}
//         </div>
