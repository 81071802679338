import React from "react";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import ProfileWishlist from "../../components/profile-wishlist";
import ProfileItems from "../../components/profile-items";
import ProfileInfo from "../../components/profile-info";
import ProfileSettings from "../../components/profile-settings";
import PrevPageLinks from "../../components/prev-page-links";

import "./profile.scss";
const getSpacing = (mobile, tablet) => {
  if (mobile) {
    return 0;
  } else if (tablet) {
    return 6;
  } else return 7;
};
const Profile = ({ match, location, width }) => {
  const { url } = match;
  const mobile = width <= 425;
  const tablet = width <= 768;
  const spacing = getSpacing(mobile, tablet);
  return (
    <div className="profile-container">
      <PrevPageLinks>
        <Link className="prev" to="/">
          Home
        </Link>{" "}
        /{" "}
        <Link className="current" to={location.pathname}>
          Profile
        </Link>
      </PrevPageLinks>
      <h2>PROFILE</h2>
      <Grid container spacing={spacing}>
        <Grid item lg={3} md={4} xs={12} sm={4}>
          <div className={`${width <= 426 ? "mobile-card" : "profile-card"}`}>
            <Link
              className={
                location.pathname === `${url}/wishlist` ||
                location.pathname === `${url}`
                  ? "current"
                  : "prev"
              }
              to={`${url}/wishlist`}
            >
              My Wishlist
            </Link>
            <Link
              className={
                location.pathname === `${url}/items` ? "current" : "prev"
              }
              to={`${url}/items`}
            >
              My Items
            </Link>
            <Link
              className={
                location.pathname === `${url}/info` ? "current" : "prev"
              }
              to={`${url}/info`}
            >
              My Profilet
            </Link>
            <Link
              className={
                location.pathname === `${url}/settings` ? "current" : "prev"
              }
              to={`${url}/settings`}
            >
              Profile settings
            </Link>
          </div>
        </Grid>

        <Grid
          item
          style={{ paddingRight: 0 }}
          container
          lg={9}
          md={8}
          sm={8}
          spacing={3}
        >
          <Switch>
            <Route path={`${url}/wishlist`} component={ProfileWishlist} />
            <Route path={`${url}/items`} component={ProfileItems} />
            <Route path={`${url}/info`} component={ProfileInfo} />
            <Route path={`${url}/settings`} component={ProfileSettings} />
            <Route path="/" component={ProfileWishlist} />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = ({ windowReducer: { width } }) => ({
  width,
});
export default connect(mapStateToProps)(Profile);
