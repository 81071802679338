import React, { useState, useEffect } from "react";

import "./profile-info.scss";
import { Divider } from "@material-ui/core";
import userImg from "../../assets/user.svg";
import axios from "../../utils/axios";
import CustomInput from "../custom-input";
import { Formik, Form } from "formik";
import CustomButton from "../custom-button";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";

import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ShowNotification from "../notification";
const ProfileInfo = () => {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    axios.post("GetMemberInfo").then((res) => {
      setUserInfo(res.data.result);
    });
  }, []);

  const [country, setCountry] = useState("EG");
  const [valid, setValid] = useState({ val: true, msg: "User not found" });
  const [isLoading, setIsLoading] = useState(false);

  // notification
  const [open, setOpen] = useState(false);
  const handleCloseMsg = () => {
    setOpen(false);
  };
  const handleOpenMsg = () => {
    setOpen(true);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const res = await axios.post("InviteFriend", {
      phone: "+" + getCountryCallingCode(country) + values.mobile,
      name: values.name,
    });
    handleOpenMsg(true);
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <div className="profile-info-container">
      <div className="image-container">
        <div>
          <img
            src={
              userInfo.avatar
                ? process.env.REACT_APP_API_IMAGE_URL + userInfo.avatar
                : userImg
            }
            alt="profile img"
          />
        </div>
      </div>
      <div className="info-container">
        <h3>{userInfo.name}</h3>
        <h5>{userInfo.email}</h5>
        <h5>{userInfo.phone}</h5>
      </div>

      <Divider className="divider" />
      {/* <div className="invite-friend">
        <h3>Invite Friends</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesettingustry.
          Lorem Ipsum has been the industry’s standard dummy Lorem Ipsum is
          simply dummy text of the printing and typesettingustry. Lorem Ipsum
          has been the industry’s standard dummy
        </p>
        <div className="invite-button" onClick={() => {}}>
          Invite Friends <img src={arrow} alt="arrow" />
        </div>
      </div> */}
      <h3>Invite Friends</h3>
      <Formik
        initialValues={{ mobile: undefined, name: "", email: "" }}
        validate={(values) => {
          setValid({ val: true });
          const errors = {};
          if (!values.name.trim()) {
            errors.name = "Name is required";
          }
          if (!values.mobile) {
            errors.mobile = "Mobile is required";
          } else if (
            !isValidPhoneNumber(
              "+" + getCountryCallingCode(country) + values.mobile
            ) ||
            !isPossiblePhoneNumber(
              "+" + getCountryCallingCode(country) + values.mobile
            ) ||
            values.mobile.toString().length < 10
          ) {
            errors.mobile = "Mobile is is wrong";
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          handleChange,
        }) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <ShowNotification
              open={open}
              msg={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleIcon size={22} className="success-icon" />
                  Invitation sent succefully
                </div>
              }
              handleCloseMsg={handleCloseMsg}
              handleOpenMsg={handleOpenMsg}
            />
            <span className="error-msg">
              {errors.name && touched.name && errors.name}
            </span>
            <div className="login-form-item">
              <CustomInput
                label="*Enter Name..."
                type="text"
                name="name"
                value={values.name}
                handleChange={handleChange}
              />
            </div>
            <span className="error-msg">
              {errors.mobile && touched.mobile && errors.mobile}
            </span>
            <span className="error-msg">
              {!valid.val && !errors.mobile && valid.msg}
            </span>
            <div className="login-form-item">
              <select
                value={country}
                name="country"
                onChange={(event) =>
                  setCountry(event.target.value || undefined)
                }
              >
                {getCountries().map((country) => (
                  <option key={country} value={country}>
                    {getUnicodeFlagIcon(country)} {country} +
                    {getCountryCallingCode(country)}
                  </option>
                ))}
              </select>
              <CustomInput
                label="*Enter mobile..."
                type="number"
                name="mobile"
                value={values.mobile}
                handleChange={handleChange}
              />
            </div>
            <div className="login-form-item">
              <CustomInput
                label="Enter Email..."
                type="email"
                name="email"
                value={values.email}
                handleChange={handleChange}
              />
            </div>
            <CustomButton
              styles={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "235px",
                height: "48px",
                color: "white",
              }}
              type="submit"
              disabled={isSubmitting}
            >
              <div>Invite</div>
              {isLoading && <CircularProgress size={20} />}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileInfo;
