import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import phoneIcon from "../../assets/phone-icon.svg";

import CustomButton from "../../components/custom-button";
import MaterialCloseIcon from "@material-ui/icons/Close";
import { createStructuredSelector } from "reselect";
import axios from "../../utils/axios";

// selectors
import { selectUserData } from "../../redux/user/selector";
import { selectWidth } from "../../redux/window-size/selector";
import CustomModal from "../custom-modal";
import CustomCard from "../custom-card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./product-price-button.scss";
import { useHistory } from "react-router";
import { Divider } from "@material-ui/core";
import LoginSignup from "../login-signup";
import ProductPriceCard from "../product-price-card";

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';


const ProductPriceButton = ({ width, step, user, productOwner, handleRemove, title, price, currency, isSponsored }) => {
  
  const [showPrice, setShowPrice] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  
  const mobile = width > 768;

  const handleShowPrice = () => {
    if(axios.defaults.headers.common["Authentication"] != null){
      setShowPrice(true);
    }else{
      handleOpenLogin();
    }
  };
  const handleOpenLogin = () => {
    setOpenLogin(true);
  }

  const handleCloseLogin = () => {
    setOpenLogin(false);
  }

  
  return (
    <>
      {axios.defaults.headers.common["Authentication"] == null && 
      (<CustomModal open={openLogin} classes="login-modal" closeModal={handleCloseLogin}>
        <LoginSignup handleCloseLogin={handleCloseLogin} />
      </CustomModal>)}
      {!showPrice && (<CustomButton
        styles={{
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          background: "#1d1d1f",
          marginBottom: "5px"
        }}
        handleClick={handleShowPrice}>
       <VisibilityOutlinedIcon fontSize="small" />
        <span style={{paddingRight: '22px', paddingLeft: '3px'}}>Show Price</span>
      </CustomButton>)}
      {showPrice && (<ProductPriceCard
                    currency={currency}
                    price={price}
                    productOwner={productOwner}
                    isSponsored={isSponsored}
                  />)}
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUserData,
  width: selectWidth,
});

export default connect(mapStateToProps)(ProductPriceButton);
