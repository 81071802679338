import React from "react";
import { Container, Link } from "@material-ui/core";
import PrevPageLinks from "../../components/prev-page-links";
import "./terms-conditions.scss";
import Header from "../../components/header";
const TermsConditions = () => {
  return (
    <div>
      <Header />
      <Container>
        <div className="terms-conditions">
          <PrevPageLinks>
            <Link className="prev" to="/">
              Home
            </Link>{" "}
            /{" "}
            <Link className="current" to="/terms-conditions">
              Terms & Conditions
            </Link>
          </PrevPageLinks>
          <h2>TERMS & CONDITIONS</h2>
          <p style={{ lineHeight: 2 }}>
            Connected built the Connected app as a Freemium app. This SERVICE is
            provided by Connected at no cost and is intended for use as is.
            <br />
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service.
            <br />
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy.
            <br />
            The Personal Information that we collect is used for providing and
            improving the Service.
            <br />
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
            <br />
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at Connected unless
            otherwise defined in this Privacy Policy.
            <br />
            Information Collection and Use <br />
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Phone number, name, email, profession,
            company.
            <br />
            The information that we request will be retained by us and used as
            described in this privacy policy.
            <br />
            The app does use third party services that may collect information
            used to identify you.
            <br />
            Link to privacy policy of third party service providers used by the
            app
            <br />
            Google Play Services <br />
            Firebase Analytics <br />
            Firebase Crashlytics <br />
            Log Data <br />
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data.
            <br />
            This Log Data may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing our Service, the time and
            date of your use of the Service, and other statistics.
            <br />
            Cookies
            <br />
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers.
            <br />
            These are sent to your browser from the websites that you visit and
            are stored on your device's internal memory.
            <br />
            This Service does not use these “cookies” explicitly. However, the
            app may use third party code and libraries that use “cookies” to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device.
            <br />
            If you choose to refuse our cookies, you may not be able to use some
            portions of this Service.
            <br />
            Service Providers <br />
            We may employ third-party companies and individuals due to the
            following reasons: <br />
            To facilitate our Service; <br />
            To provide the Service on our behalf; <br />
            To perform Service-related services; or <br />
            To assist us in analyzing how our Service is used.
            <br />
            We want to inform users of this Service that these third parties
            have access to your Personal Information.
            <br />
            The reason is to perform the tasks assigned to them on our behalf.
            <br />
            However, they are obligated not to disclose or use the information
            for any other purpose.
            <br />
            Security
            <br />
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it.
            <br />
            But remember that no method of transmission over the internet, or
            method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
            <br />
            Links to Other Sites <br />
            This Service may contain links to other sites.
            <br />
            If you click on a third-party link, you will be directed to that
            site.
            <br />
            Note that these external sites are not operated by us. Therefore, we
            strongly advise you to review the Privacy Policy of these websites.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or
            services.
            <br />
            Children’s Privacy
            <br />
            These Services do not address anyone under the age of 13.
            <br />
            We do not knowingly collect personally identifiable information from
            children under 13.
            <br />
            In the case we discover that a child under 13 has provided us with
            personal information, we immediately delete this from our servers.
            <br />
            If you are a parent or guardian and you are aware that your child
            has provided us with personal information, please contact us so that
            we will be able to do necessary actions.
            <br />
            Changes to This Privacy Policy <br />
            We may update our Privacy Policy from time to time.
            <br />
            Thus, you are advised to review this page periodically for any
            changes.
            <br />
            We will notify you of any changes by posting the new Privacy Policy
            on this page.
            <br />
            These changes are effective immediately after they are posted on
            this page.
            <br />
            Contact Us <br />
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at <b>support@connectedapp.com</b>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default TermsConditions;
