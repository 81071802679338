import React, { useState } from "react";
import ClosingIcon from "../closing-icon";
import CustomCard from "../custom-card";
import Login from "./Login";

import "./login-signup.scss";
import Signup from "./Signup";
import Verify from "./Verify";

const LoginSignup = ({ settings, handleCloseLogin }) => {
  const [step, setStep] = useState(1); //1 for mobile 0 for verify
  const [type, setType] = useState(1); //1 for login 0 for sign up
  const [number, setNumber] = useState(null);
  return settings ? (
    <>
      {type === 1 ? (
        step === 1 ? (
          <Login
            settings={settings}
            setStep={setStep}
            setNumber={setNumber}
            setType={setType}
          />
        ) : (
          <Verify settings={settings} number={number} />
        )
      ) : step === 1 ? (
        <Signup setStep={setStep} setType={setType} />
      ) : null}
    </>
  ) : (
    <div className="login-container">
      <CustomCard classes="login-card">
        <ClosingIcon
          styles={{ position: "absolute", top: "15px", right: "16px" }}
          handleClose={handleCloseLogin}
        />
        {type === 1 ? (
          step === 1 ? (
            <Login setStep={setStep} setNumber={setNumber} setType={setType} />
          ) : (
            <Verify number={number} />
          )
        ) : step === 1 ? (
          <Signup setStep={setStep} setType={setType} />
        ) : (
          <div>
            <h2>Congratulations</h2>
            <p>
              Account created successfully and you will receive an SMS once
              accepted
            </p>
          </div>
        )}
      </CustomCard>
    </div>
  );
};

export default LoginSignup;
