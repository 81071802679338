import React from "react";
import { Grid } from "@material-ui/core";

import "./images-box.scss";

const ImagesBox = ({ imgs, openImgs }) => {
  const listImgs = imgs.slice(0, 4);
  return (
    <Grid
      className="images-box-container"
      container
      item
      lg={12}
      md={12}
      sm={6}
      xs={6}
      spacing={1}
    >
      {listImgs.map((img, i) => (
        <Grid item lg={6} md={6} sm={6} xs={6}>
          {i === listImgs.length - 1 ? (
            <div className="hidden-img" onClick={openImgs}>
              <img
                key={img}
                src={process.env.REACT_APP_API_IMAGE_URL + img}
                alt="item img"
              />
              <div className="hide-img"></div>
              <div className="plus-img">+</div>
            </div>
          ) : (
            <img
              key={img}
              src={process.env.REACT_APP_API_IMAGE_URL + img}
              alt="item img"
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ImagesBox;
