import React from "react";
import Grid from "@material-ui/core/Grid";
import "./featured-products.scss";
import ProductCard from "../product-card";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const FeaturedProducts = ({ products, handleChangeStatus }) => {
  return (
    <div className="home-featured-products">
      <h1 style={{ color: "#E6E8FA" }}>FEATURED PRODUCTS</h1>
      <Grid container spacing={3}>
        {products.map((item) =>
          item ? (
            <Grid
              style={{}}
              key={item.document.itemId}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <ProductCard
                requestVIPServices={item.document.requestVIPServices}
                currency={item.document.currency}
                name={item.document.title}
                state={item.document.state}
                price={item.document.price}
                prodLocation={item.document.location}
                img={
                  process.env.REACT_APP_API_IMAGE_URL +
                  item.document.media[0].link
                }
                mediaType={item.document.media[0]}
                status={item.document.status}
                id={item.document.itemId}
                isSponsored={item.document.sponsoredBy === "true"}
                handleChangeStatus={handleChangeStatus}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box pt={0.5}>
                <Skeleton variant="rect" height={196} />
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

export default FeaturedProducts;
