import React, { useEffect, useState } from "react";

import "./profile-wishlist.scss";
import ProductCard from "../product-card";
import { Grid, Snackbar, IconButton, Box } from "@material-ui/core";
import MaterialCloseIcon from "@material-ui/icons/Close";
import axios from "../../utils/axios";
import { Skeleton } from "@material-ui/lab";

const ProfileWishlist = () => {
  const [products, setProducts] = useState([null, null, null, null, null, null]);
  const [locations, setLocations] = useState([]);
  const [firstTime, setFirstTime] = useState(true);

  const getLocations = async () => {
    const res = await axios.get("GetLocations");
    setLocations(res.data.result);
  };
  useEffect(() => {
    if (firstTime) {
      getLocations();
      setFirstTime(false);
    }
    if (locations.length)
      axios.post("GetWishlistedItems").then(res => {
        const products = res.data.result.items.map(ele => {
          const locName = locations.find(loc => loc.partitionKey === ele.location)?.name;
          ele.location = locName;
          return ele;
        });
        setProducts(products);
      });
  }, [locations]);

  const handleChangeStatus = id => {
    axios.post("RemoveItemFromWishlist", { itemId: id }).then(res => {
      handleShowMsg({
        vertical: "top",
        horizontal: "center",
        msg: "Item removed successfully",
        openMsg: true,
      });
      setProducts(products => products.filter(ele => ele.partitionKey !== id));
    });
  };

  const [state, setState] = useState({
    openMsg: false,
    vertical: "top",
    horizontal: "center",
    msg: "",
  });
  const { openMsg, vertical, horizontal, msg } = state;
  const handleShowMsg = newState => {
    setState({ openMsg: true, ...newState });
  };

  const handleCloseMsg = () => {
    setState({ openMsg: false });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openMsg}
        onClose={handleCloseMsg}
        message={msg}
        key={vertical + horizontal}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseMsg}>
              <MaterialCloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      {products.map(item => (
        <Grid key={item?.partitionKey} item xs={12} sm={6} md={6} lg={4}>
          {item ? (
            <ProductCard
              requestVIPServices={item.requestVIPServices}
              name={item.title}
              currency={item.currency}
              state={true}
              price={item.price}
              prodLocation={item.location}
              img={process.env.REACT_APP_API_IMAGE_URL + item.media[0].link}
              mediaType={item.media[0]}
              status={item.status}
              id={item.partitionKey}
              isSponsored={item.sponsoredBy === "true"}
              handleChangeStatus={handleChangeStatus}
              profile
            />
          ) : (
            <Box pt={0.5}>
              <Skeleton variant="rect" height={196} />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          )}
        </Grid>
      ))}
    </>
  );
};

export default ProfileWishlist;
