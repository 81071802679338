import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Grid } from "@material-ui/core";
import { Upload } from "antd";
import CustomInput from "../../components/custom-input";
import "./profile-settings.scss";
import userImg from "../../assets/user.svg";
import axios from "../../utils/axios";
import CustomButton from "../custom-button";
import LoginSignup from "../login-signup";
import { selectWidth } from "../../redux/window-size/selector";
import { selectUserData } from "../../redux/user/selector";
import { createStructuredSelector } from "reselect";
import { CircularProgress } from "@material-ui/core";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const ProfileSettings = ({ match, width, user }) => {
  const mobile = width <= 768;
  const spacing = mobile ? 0 : 3;
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios.post("GetMemberInfo").then((res) => {
      setUserInfo(res.data.result);
    });
  }, []);
  const handleChange = (e) => {
    const { value, name } = e.target;

    setUserInfo({ [name]: value });
  };
  const handleImgChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setUserInfo({ ["avatar"]: info.file.response.result.name });
      });
    }
  };
  const handleSave = () => {
    setLoading(true);
    axios
      .post("UpdateProfile", {
        Name: userInfo.name,
        Email: userInfo.email,
        Company: userInfo.company,
        Profession: userInfo.profession,
        Avatar: userInfo.avatar,
      })
      .then((res) => {
        setLoading(false);
      });
  };
  return (
    <div className="profile-settings-container">
      <h4>Profile Information</h4>
      <div className="form-container">
        <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={process.env.REACT_APP_API_BASE_URL + "UploadImage/IMAGE"}
            headers={{ Authentication: user.token }}
            onChange={handleImgChange}
          >
            <div className="image-container">
              {userInfo.avatar ? (
                <img
                  src={process.env.REACT_APP_API_IMAGE_URL + userInfo.avatar}
                  alt="avatar"
                />
              ) : (
                <img src={userImg} alt="avatar" />
              )}
            </div>
          </Upload>

          <h3 style={{ textAlign: "center" }}>Add Photo</h3>
        </div>
        <div className="data-container">
          <Grid container spacing={spacing}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInput
                label="Enter your name.."
                value={userInfo.name}
                type="text"
                name="name"
                handleChange={handleChange}
                styles={{ width: "90%" }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInput
                label="Enter your email.."
                value={userInfo.email}
                type="email"
                name="email"
                handleChange={handleChange}
                styles={{ width: "90%" }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInput
                label="Enter your profession.."
                value={userInfo.profession}
                type="text"
                name="profession"
                handleChange={handleChange}
                styles={{ width: "90%" }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInput
                label="Enter your company.."
                value={userInfo.company}
                type="text"
                name="company"
                handleChange={handleChange}
                styles={{ width: "90%" }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <CustomButton
          styles={{
            color: "white",
            width: "235px",
            height: "48px",
            marginLeft: "auto",
          }}
          handleClick={handleSave}
          disabled={loading}
        >
          Save Changes {loading && <CircularProgress size={20} />}
        </CustomButton>
      </div>
      <Divider className="divider" />
      <div className="change-phone">
        <h4>Change your phone</h4>
        <div className="login-container">
          <LoginSignup settings />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  width: selectWidth,
  user: selectUserData,
});
export default connect(mapStateToProps)(ProfileSettings);
