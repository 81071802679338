import React from "react";
import { Link } from "react-router-dom";
import "./highlights-card.scss";
const HighlightsCard = ({ id, img, name, state }) => {
  const grad2 =
    "linear-gradient(45deg, #40c4ff 0%, #0042ad 100%),linear-gradient(142deg, #0000ff, #001f52),linear-gradient(108deg, #001f52, #001f52),linear-gradient(108deg, #001f52, #40c4ff)";
  const changeState = (key) => {
    const items = JSON.parse(localStorage["Highlights"]);
    const item = items.find((ele) => ele.partitionKey === key);
    item.state = 0;
    localStorage["Highlights"] = JSON.stringify(items);
  };
  return (
    <Link
      onClick={() => changeState(id)}
      to={`/products-details/${id}`}
      className="highlight-card"
    >
      <div
        className="highlight-image-container"
        style={{
          background: state === 1 ? grad2 : "#7A7171",
        }}
      >
        <img alt="highlight" src={img} />
      </div>
      <div className="highlight-card-data">
        <h4>{name}</h4>
      </div>
    </Link>
  );
};

export default HighlightsCard;
