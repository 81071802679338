import React, { useState } from "react";
import styles from "./FaqPage.module.scss";
import Header from "../../components/header";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

const Faq = () => {
  const [activeTab, setActiveTab] = useState("users");

  return (
    <div>
      <Header />
      <Container>
        <div className={styles.pagesLayout}>
          <div className={styles.faq}>
            <div className={styles.prevPageLinks}>
              <Link className={`prev`} to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className={`current`} to="/faq">
                FAQ
              </Link>
            </div>
            <h2>Frequently Asked Questions</h2>
            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                <button
                  className={`${styles.tab} ${
                    activeTab === "users" ? styles.selected : ""
                  }`}
                  onClick={() => setActiveTab("users")}
                >
                  For Users
                </button>
                <button
                  className={`${styles.tab} ${
                    activeTab === "businesses" ? styles.selected : ""
                  }`}
                  onClick={() => setActiveTab("businesses")}
                >
                  For Businesses
                </button>
              </div>
              <div className={styles.tabContent}>
                {activeTab === "users" && (
                  <div className={styles.tabPanel}>
                    <p>
                      <b>
                        1. How can I become a VIP member and gain access to the
                        luxury marketplace?
                      </b>
                      <br />• VIP membership: To become a VIP member, apply
                      through the app. Membership grants exclusive access to the
                      luxury marketplace.
                    </p>
                    <p>
                      <b>
                        2. What types of luxury products are available on the
                        Connected App?
                      </b>
                      <br />• Luxury Products: Explore real estate, lifestyle,
                      and automotive categories for a curated selection of
                      luxury items.
                    </p>
                    <p>
                      <b>3. How do I create an account on the app?</b>
                      <br />• Account Creation: Register by providing required
                      details and create your account hassle-free.
                    </p>
                    <p>
                      <b>
                        4. How can I browse and search for specific luxury
                        items?
                      </b>
                      <br />• Browsing & Searching: Easily browse and find
                      specific luxury products using our intuitive search
                      filters.
                    </p>
                    <p>
                      <b>
                        5. Can I negotiate prices with sellers within the app?
                      </b>
                      <br />• Price Negotiation: Yes, users can negotiate prices
                      directly with sellers on the app.
                    </p>
                    <p>
                      <b>
                        6. Is there a verification process for sellers and
                        buyers to ensure authenticity and security?
                      </b>
                      <br />• Verification & Security: Sellers and buyers
                      undergo a verification process for authenticity and secure
                      transactions.
                    </p>
                    <p>
                      <b>7. What payment methods are accepted on the app?</b>
                      <br />• We only accept deposit and you receive the
                      required product from 2-5 days.
                    </p>
                    <p>
                      <b>
                        8. Are there any shipping or delivery options available
                        for purchased items?
                      </b>
                      <br />• Shipping & Delivery: Enjoy flexible shipping and
                      delivery options for your purchased items.
                    </p>
                    <p>
                      <b>
                        9. How does the exchange process work if I want to trade
                        my luxury item with another user?
                      </b>
                      <br />• Exchange Process: Trade your luxury item with
                      another user easily via the app.
                    </p>
                    <p>
                      <b>
                        10. How can I track the status of my transactions and
                        purchases?
                      </b>
                      <br />• Transaction Tracking: Stay updated on your
                      transactions and purchases through your account.
                    </p>
                    <p>
                      <b>
                        11. How can I contact customer support if I have a
                        question or encounter an issue?
                      </b>
                      <br />• Customer Support:{" "}
                      <a href="https://wa.me/201027651515">Click Here</a> to
                      contact our support team.
                    </p>
                  </div>
                )}
                {activeTab === "businesses" && (
                  <div className={styles.tabPanel}>
                    <p>
                      <b>
                        1. How can I become a verified seller on the Connected
                        App?
                      </b>
                      <br />• Verified Seller: Get verified as a seller by
                      choosing one of Connected VIP Packages.
                    </p>
                    <p>
                      <b>
                        2. What are the requirements for listing luxury products
                        on the platform?
                      </b>
                      <br />• Listing Requirements: Businesses must meet certain
                      requirements to list products on the app.
                    </p>
                    <ol style={{ paddingLeft: "3rem" }}>
                      <li>
                        Luxury Brands and Latest Model Years: The Connected App
                        exclusively supports luxury brands featuring their
                        latest model releases.
                      </li>
                      <li>
                        Professional and Detailed Photos: Ensure that your
                        products are showcased with professional and
                        meticulously detailed photographs when listing them on
                        the Connected App.
                      </li>
                      <li>
                        Authentic Products with Guarantees: Only authentic
                        products accompanied by their respective guarantees are
                        permitted on the Connected App.
                      </li>
                      <li>
                        Full Product Details: To successfully list your products
                        on the app, provide comprehensive and complete
                        information about each item, leaving no essential
                        details omitted
                      </li>
                      <li>
                        Every business must select one of our two available
                        services: VIP service or Self-service. This choice is
                        mandatory for all users of the Connected App
                      </li>
                    </ol>
                    <p>
                      <b>
                        3. Can businesses promote their products or services
                        through the app?
                      </b>
                      <br />• Promotions: Explore opportunities to promote your
                      luxury products or services through Connected VIP
                      Packages.
                    </p>
                    <p>
                      <b>
                        4. Are there any fees or commissions for listing and
                        selling products on the app?
                      </b>
                      <br />• Fees & Commissions: Applicable fees and
                      commissions for listings and sales, check it through the
                      VIP service.
                    </p>
                    <p>
                      <b>
                        5. Is there a limit on the number of products a business
                        can list on the app?
                      </b>
                      <br />• Listing Limits: There's a limit on the number of
                      products businesses can list according to your chosen
                      package, Connected VIP Packages or if you chose the VIP
                      service there is no limit.
                    </p>
                    <p>
                      <b>
                        6. Are businesses provided with analytics or insights
                        into their listings' performance?
                      </b>
                      <br />• Analytics & Insights: We send to you performance
                      insights for your listings according to your chosen
                      package, Connected VIP Packages.
                    </p>
                    <p>
                      <b>
                        7. Can businesses feature or highlight their products to
                        increase visibility?
                      </b>
                      <br />• Featured Listings: Highlight your products to
                      boost visibility through checking Connected VIP Packages.
                    </p>
                    <p>
                      <b>
                        8. Are there any marketing or promotional opportunities
                        for businesses on the app?
                      </b>
                      <br />• Connected APP can support their businesses with
                      all marketing campaigns like SMS, WhatsApp, Emails and
                      media campaigns.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Faq;
