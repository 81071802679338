import React, { useEffect, useState } from "react";

import "./filter-list.scss";
import FilterSection from "../filter-section";
import PriceSlider from "./PriceSlider";

const FilterList = ({ filtersList, handleSelect, handleFilterPrice }) => {
  const [filters, setFilters] = useState([]);
  const [priceCollapse, setPriceCollapse] = useState(false);
  const [price, setPrice] = React.useState([0, 100000000]);
  useEffect(() => {
    setFilters(filtersList);
  }, [filtersList]);
  const handleCollapse = (title) => {
    const filtersUpdated = filters.map((ele) => {
      if (ele.title === title) {
        return { ...ele, opened: !ele.opened };
      }
      return ele;
    });
    setFilters([...filtersUpdated]);
  };
  const handleCollapsePrice = () => {
    setPriceCollapse((state) => !state);
  };

  const handleChangePrice = (event, newValue) => {
    if (newValue[0] - newValue[1] === 1000000) return false;

    setPrice(newValue);
  };

  const handleClick = () => {
    handleFilterPrice(price[0], price[1]);
  };
  return (
    <div className="filters-container">
      {filters.length ? (
        <>
          <FilterSection
            title="Price"
            list={[]}
            closing={true}
            opened={priceCollapse}
            handleCollapse={handleCollapsePrice}
          >
            <PriceSlider
              price={price}
              handleChangePrice={handleChangePrice}
              handleClick={handleClick}
            />
          </FilterSection>
          {/* <FilterSection
            position={0}
            handleSelect={handleSelect}
            handleCollapse={handleCollapse}
            {...filters[0]}
          /> */}
        </>
      ) : null}
      {filters.slice(1).map((ele) => (
        <FilterSection
          position={ele.position}
          handleSelect={handleSelect}
          key={ele.title}
          handleCollapse={handleCollapse}
          {...ele}
        />
      ))}
    </div>
  );
};

export default FilterList;
