import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import windowReducer from "./window-size/reducer";
import userReducer from "./user/reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const rootReducer = combineReducers({
  user: userReducer,
  windowReducer,
});
export default persistReducer(persistConfig, rootReducer);
