import React from "react";

import { Divider, Collapse } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import "./filter-section.scss";
import MinuPlus from "./minus-plus";
const FilterSection = ({
  children,
  title,
  list,
  closing,
  opened,
  handleCollapse,
  handleSelect,
  position,
  styles,
}) => {
  return (
    <div className="filter-section">
      <div style={{ marginTop: closing ? "13px" : 0 }} className="filter-title">
        <h4>{title}</h4>
        <MinuPlus
          closing={closing}
          handleClick={handleCollapse}
          title={title}
          opened={opened}
        />
      </div>

      <Collapse
        className="custom__collapse-filter"
        style={{ ...styles }}
        in={opened}
        timeout="auto"
        unmountOnExit
      >
        <Divider className="divider" />
        {list?.map((ele) => (
          <div className="attribute-container" key={ele.name}>
            <div>
              <Checkbox
                // checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                // icon={<RadioButtonUncheckedIcon fontSize="small" />}
                size="small"
                color="white"
                className="filter-check-box"
                inputProps={{ "aria-label": "checkbox for attributes" }}
                onClick={(e) => handleSelect(e, position, ele.partitionKey)}
              />
            </div>
            <div>
              <p>{ele.name}</p>
            </div>
          </div>
        ))}
        {children}
      </Collapse>
    </div>
  );
};

export default FilterSection;
