import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Colors } from "../../constants";
import "./custom-select.scss";
const CustomSelect = ({ options, label, value, name, handleChange }) => {
  return (
    <>
      <InputLabel
        htmlFor={`outlined-${label}`}
        style={{
          color: "white",
          padding: "0 5px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        style={{ color: "white", height: "48px" }}
        native
        value={value}
        onChange={handleChange}
        name={name}
        id={`outlined-${label}`}
      >
        <option className="select-option" aria-label="None" value="" />
        {options.map(({ id, partitionKey, name, title, text }) => (
          <option
            className="select-option"
            key={id || partitionKey}
            value={id || partitionKey}
          >
            {name || title || text}
          </option>
        ))}
      </Select>
    </>
  );
};

export default CustomSelect;
