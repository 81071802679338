import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/selector";
import PrevPageLinks from "../../components/prev-page-links";
import "./concierge.scss";
import { Link } from "react-router-dom";
import { Box, Container, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "../../utils/axios";
import AdCard from "./AdCard";
import Header from "../../components/header";

const Concierge = ({ user }) => {
  const [services, setServices] = useState([null, null, null]);
  useEffect(() => {
    axios.get("GetConcierge").then((res) => {
      setServices(res.data.result);
    });
  }, []);

  return (
    <div>
      <Header />
      <Container>
        <div className="concierge-container">
          <PrevPageLinks>
            <Link className="prev" to="/">
              Home
            </Link>{" "}
            /{" "}
            <Link className="current" to="/concierge">
              Premium Advertisement
            </Link>
          </PrevPageLinks>
          <h2>Premium Advertisement</h2>
          <Grid container alignContent="stretch" spacing={3}>
            {services.map((ele) =>
              ele ? (
                <Grid item lg={4} md={4} sm={12} style={{ width: "100%" }}>
                  <AdCard
                    description={ele.description}
                    media={ele.media[0]}
                    title={ele.title}
                  />
                </Grid>
              ) : (
                <Grid item lg={4} md={4} sm={12}>
                  <Box pt={0.5}>
                    <Skeleton
                      variant="circle"
                      style={{ margin: "auto" }}
                      height={50}
                      width={50}
                    />
                    <Skeleton />
                    <Skeleton />
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUserData,
});
export default connect(mapStateToProps)(Concierge);
