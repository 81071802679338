import React, { useState } from "react";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const CardLabel = status => {
  let color, bg;
  color = "#FFFFFF";
  switch (status) {
    case "APPROVED":
      bg = "#178BFC";
      break;
    case "SOLDOUT":
      bg = "#FFFFFF";
      color = "#0E0E0E";
      break;
    case "RESERVED":
      bg = "#707070";
      break;
    case "REJECTED":
      bg = "red";
      break;
    default:
      bg = "";
      color = "#1d1d1f";
      break;
  }
  return (
    <div className="profile-card-label" style={{ color: color, background: bg }}>
      {status}
    </div>
  );
};

const AdCard = props => {
  const { description, media, title } = props;

  return (
    <div className="ads-card-container" style={{ margin: 0 }}>
      <div className="product-data-container">
        <div className="product-data" style={{ paddingBottom: "10px" }}>
          {media.type === "VIDEO" ? (
            <video muted controls autoPlay className="product-img" style={{ cursor: "pointer" }} alt="product">
              <source src={process.env.REACT_APP_API_IMAGE_URL + media.link} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          ) : (
            <img
              className="product-img"
              style={{ cursor: "pointer" }}
              alt="product"
              src={process.env.REACT_APP_API_IMAGE_URL + media.link}
            />
          )}
          <div style={{ margin: "10px 0 0 10px" }}>
            <h5 style={{ cursor: "pointer", marginTop: 2, marginBottom: 0, display: "flex", alignItems: "center" }}>
              {title}{" "}
            </h5>
            <div className={`sponsored-icon`}>Sponsored</div>
            <div className="product-details-description">
              <p style={{ maxWidth: "443px" }}>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdCard;
