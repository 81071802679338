import React, { useState } from "react";
import "./Services.scss";
import { Container } from "@material-ui/core";
import services1 from "../../assets/services-1.png";
import services2 from "../../assets/services-2.png";
import services3 from "../../assets/services-3.png";
import services4 from "../../assets/services-4.png";
import services5 from "../../assets/services-5.png";
import services6 from "../../assets/services-6.png";
import Header from "../../components/header";

const servicesData = [
  {
    icon: services1,
    title: "Selling",
    shortDescription:
      "Sell your luxury products with unmatched ease and reach the right community, with the right package...",
    longDescription:
      "Sell your luxury products with unmatched ease and reach the right community, with the right package. We offer tailored solutions to maximize the value of your sale, whether through auctions, direct sales, or private collections. Our team of experts is here to guide you through every step of the process.",
  },
  {
    icon: services2,
    title: "Self Service",
    shortDescription:
      "If you're looking to sell your luxury product yourself, you can use our self-service platform. Simpl...",
    longDescription:
      "If you're looking to sell your luxury product yourself, you can use our self-service platform. Simply list your item, set your price, and manage your listing through our user-friendly dashboard. We provide all the tools and support you need to sell your items quickly and efficiently.",
  },
  {
    icon: services3,
    title: "VIP Service",
    shortDescription:
      "The most convenient and premium way to sell your luxury product. Let us take care of everything, fro...",
    longDescription:
      "The most convenient and premium way to sell your luxury product. Let us take care of everything, from listing your item to negotiating the sale to marketing your product. All you have to do is hand us your product, and we'll do the rest. We'll use our extensive network of buyers to reach a wider audience, and we'll use the right marketing tools (Social media, Emails, WhatsApp, Phonecalls) to get your product the attention it deserves. We'll also handle all of the paperwork and delivery, so you can relax and know that you are in good hands.",
  },
  {
    icon: services4,
    title: "Exchange",
    shortDescription:
      "Looking to upgrade/downgrade? Let us help. We offer a hassle-free exchange service that will take ca...",
    longDescription:
      "Looking to upgrade/downgrade? Let us help. We offer a hassle-free exchange service that will take care of everything for you. Whether you're looking for something new or something old, we can help you find the perfect match.",
  },
  {
    icon: services5,
    title: "Buying",
    shortDescription:
      "Find the luxury you've been always looking for; New, resale, limited editions, rare pieces, vintage,...",
    longDescription:
      "Find the luxury you've been always looking for; New, resale, limited editions, rare pieces, vintage, and more. Our extensive network of sellers allows us to bring you the best deals and the most unique items. Whether you're a collector or simply looking for something special, we can help you find what you need.",
  },
  {
    icon: services6,
    title: "Consulting",
    shortDescription:
      "Trust us to guide you to the right luxury sale. Our team of expert consultants will assist you with u...",
    longDescription:
      "Trust us to guide you to the right luxury sale. Our team of expert consultants will assist you with understanding the market, appraising your items, and making informed decisions. We provide personalized advice and strategies to help you achieve the best results.",
  },
];

const Services = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className="services">
      <Header />
      <Container>
        <h2>Services</h2>
        <div className="services-grid">
          {servicesData.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-icon">
                <img src={service.icon} alt="services 1" />
              </div>
              <h3>{service.title}</h3>
              <p>
                {expandedIndex === index
                  ? service.longDescription
                  : service.shortDescription}
              </p>
              <button onClick={() => toggleExpand(index)}>
                {expandedIndex === index ? "Read Less" : "Read More"}
              </button>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Services;
