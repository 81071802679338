import React from "react";
import { Colors } from "../../constants";
import "./custom-button.scss";

const CustomButton = ({ children, type, handleClick, styles, ...props }) => {
  return (
    <button
      className="submit-home-search"
      style={{
        backgroundColor: Colors.secondary,
        border: "none",
        borderRadius: "4px",
        outline: "none",
        cursor: "pointer",
        ...styles,
      }}
      type={type}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
