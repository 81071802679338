import React from "react";
import FilterList from "../filters-list";

import "./filter-list-mobile.scss";
import ClosingIcon from "../closing-icon";
const FilterListMobile = ({
  handleToggleFilter,
  handleFilterPrice,
  handleSelect,
  filtersList,
}) => {
  return (
    <div className="filter-list-mobile">
      <div>
        <ClosingIcon
          styles={{ position: "absolute", top: "58px", right: "16px" }}
          handleClose={() => handleToggleFilter(false)}
        />
        <h3>Filter</h3>
        <FilterList
          handleFilterPrice={handleFilterPrice}
          handleSelect={handleSelect}
          filtersList={filtersList}
        />
      </div>
    </div>
  );
};
export default FilterListMobile;
