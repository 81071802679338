import React from "react";
import { Link } from "react-router-dom";
import "../AboutUs/AboutUs.scss";

const ProductLinks = () => {
  return (
    <div className="about-us-container">
      <div className="about-us">
        <p>Products</p>
        <div className="about-us-dropdown">
          <Link to="/automotive">Automotive</Link>
          <Link to="/life-style">Lifestyle</Link>
          <Link to="/real-estate">Realestate</Link>
        </div>
      </div>
    </div>
  );
};

export default ProductLinks;
