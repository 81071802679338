import React from "react";
import ProductPrice from "../product-price";

import "./product-price-card.scss";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/selector";
import { connect } from "react-redux";
const ProductPriceCard = ({ price, currency, isSponsored }) => {
  return (
    <div className="product-price-card">
      <div>
        <ProductPrice isSponsored={isSponsored} currency={currency} styles={{ fontSize: "15px" }} price={price} />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUserData,
});
export default connect(mapStateToProps)(ProductPriceCard);
