import React from "react";

import "./product-location.scss";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
const ProductLocation = ({ productDetails, prodLocation, styles }) => {
  return (
    <div style={{ ...styles }} className={`location-icon ${productDetails? 'product-details-location-icon':''}`}>
      <LocationOnOutlinedIcon className="product-details-location" style={{ ...productDetails }} /> {prodLocation}
    </div>
  );
};

export default ProductLocation;
