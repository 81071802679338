import React, { useState } from "react";
import { connect } from "react-redux";
import { FormControl } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grow from "@material-ui/core/Grow";

import CustomSelect from "../../components/custom-select";
import FilterListMobile from "../../components/filter-list-mobile";

import { Colors } from "../../constants";
import FilterIcon from "../../assets/fliter.svg";
import "./products-header.scss";
import CustomButton from "../custom-button";
import { createStructuredSelector } from "reselect";
import { selectWidth } from "../../redux/window-size/selector";

const sortByList = [
  { id: 1, text: "Price: Low to High" },
  { id: 2, text: "Price: High to Low" },
  { id: 3, text: "Alphabetically: A ~ Z" },
  { id: 4, text: "Alphabetically: Z ~ A" },
];

const ProductsHeader = ({
  handleSortBy,
  sortBy,
  width,
  showFilter,
  handleFilterPrice,
  handleSelect,
  filtersList,
}) => {
  const [mobileFilter, setMobileFilter] = useState(false);
  const handleToggleFilter = (value) => {
    setMobileFilter(value);
  };
  const mobile = width < 768;
  return (
    <div
      className="products-header"
      style={{ display: mobile ? "block" : "flex" }}
    >
      <h3 style={{ textTransform: "uppercase" }}>
        Search Results for automotive
      </h3>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        {mobile ? (
          <>
            <CustomButton
              type="button"
              styles={{
                color: "#FFFFFF",
                height: "48px",
                width: "100%",
              }}
              handleClick={() => setMobileFilter(true)}
            >
              <img src={FilterIcon} alt="filter" />
              Filters (24)
            </CustomButton>

            <Modal
              aria-labelledby="transition-modal-filter"
              aria-describedby="transition-modal-filter"
              className="filter-modal"
              open={mobileFilter}
              onClose={() => setMobileFilter(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Grow
                in={mobileFilter}
                {...(mobileFilter ? { timeout: 2000 } : {})}
              >
                <FilterListMobile
                  handleFilterPrice={handleFilterPrice}
                  handleSelect={handleSelect}
                  filtersList={filtersList}
                  handleToggleFilter={handleToggleFilter}
                />
              </Grow>
            </Modal>
          </>
        ) : null}
        <FormControl
          variant="outlined"
          style={{
            color: Colors.secondary,
            height: "48px",
            width: "100%",
            marginTop: "7px",
          }}
        >
          <CustomSelect
            options={sortByList}
            label="Sort by"
            value={sortBy}
            name="sort by"
            handleChange={handleSortBy}
          />
        </FormControl>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  width: selectWidth,
});
export default connect(mapStateToProps)(ProductsHeader);
