import React from "react";

import HomeSlider from "../../components/home-slider";
import HomeAdvancedSearch from "../../components/home-advanced-search";
import "./home.scss";
import Highlights from "../../components/highlights";
import FeaturedProducts from "../../components/featured-products";
import RecommendedProducts from "../../components/recommended-products";
import axios from "../../utils/axios";
import Header from "../../components/header";
import { Container } from "@material-ui/core";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [
        {
          label: "San Francisco – Oakland Bay Bridge, United States",
          imgPath:
            "https://www.connectedapp.com/static/media/banner_1.a2c1b47f.mp4",
        },
        {
          label: "Bird",
          imgPath:
            "https://www.connectedapp.com/static/media/banner_3.c19f49b3.mp4",
        },
        {
          label: "Bali, Indonesia",
          imgPath:
            "https://www.connectedapp.com/static/media/banner_2.abcbaa11.mp4",
        },
        // {
        //   label: "NeONBRAND Digital Marketing, Las Vegas, United States",
        //   imgPath:
        //     "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60",
        // },
        // {
        //   label: "Goč, Serbia",
        //   imgPath:
        //     "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
        // },
      ],
      locations: [],
      categories: [],
      highlights: [],
      products: [null, null, null, null, null, null, null, null],
      recommended: [],
    };
  }
  async componentDidMount() {
    let wishlist;
    try {
      wishlist = await (
        await axios.post("GetWishlistedItems")
      )?.data?.result?.items;
    } catch (e) {
      wishlist = [];
    }
    const locations = await axios.get("GetLocations");
    this.setState({ locations: locations.data.result });
    axios.get("GetCategories").then((res) => {
      this.setState({
        categories: res.data.result.map((ele) => {
          return { ...ele, partitionKey: ele.name };
        }),
      });
    });

    axios
      .post("GetItemsHome", {
        itemsPerPage: 8,
        page: 0,
      })
      .then((res) => {
        const products = res?.data?.result?.value.map((ele) => {
          const existEle = wishlist?.find(
            (item) => item.partitionKey === ele.document.itemId
          );
          if (existEle) {
            ele.document.state = true;
          }
          const locName = locations.data.result.find(
            (loc) => loc.partitionKey === ele.document.location
          )?.name;
          ele.document.location = locName;
          return ele;
        });
        this.setState({ products: products || [] });
      });
    axios.get("GetItemHighlights/Highlights").then((res) => {
      const items = res.data.result.map((ele) => ({ ...ele, state: 1 }));
      this.setState({
        highlights: items,
      });
      localStorage["Highlights"] = JSON.stringify(items);
    });
    axios.get("GetItemHighlights/Highlights").then((res) => {
      // it should be search not Highlights
      // recommended
      const recommended = res.data.result.map((ele) => {
        const existEle = wishlist?.find(
          (item) => item.partitionKey === ele.partitionKey
        );
        if (existEle) {
          ele.state = true;
        }
        const locName = locations.data.result.find(
          (loc) => loc.partitionKey === ele.location
        )?.name;
        const attr = Object.entries(ele.attributes).map((ele) => ({
          [ele[0]]: ele[1],
        }));
        ele.attributes = attr;
        ele.location = locName;
        return ele;
      });
      this.setState({ recommended });
    });
  }
  handleChangeFeaturedStatus = (id) => {
    const { products } = this.state;
    let newState = false;
    this.setState({
      products: products.map((ele) => {
        if (ele.document.itemId === id) {
          newState = !ele.document.state;
          ele.document.state = !ele.document.state;
        }
        return ele;
      }),
    });
    const type = newState ? "AddItemToWishlist" : "RemoveItemFromWishlist";
    axios
      .post(type, { itemId: id })
      .then((res) => {})
      .catch((err) => {
        this.setState({
          products: products.map((ele) => {
            if (ele.document.itemId === id) {
              newState = !ele.document.state;
              ele.document.state = !ele.document.state;
            }
            return ele;
          }),
        });
      });
  };
  handleChangeRecommendedStatus = (id) => {
    const { recommended } = this.state;
    let newState = false;
    this.setState({
      recommended: recommended.map((ele) => {
        if (ele.partitionKey === id) {
          newState = !ele.state;
          return {
            ...ele,
            state: !ele.state,
          };
        }
        return ele;
      }),
    });
    const type = newState ? "AddItemToWishlist" : "RemoveItemFromWishlist";
    axios
      .post(type, { itemId: id })
      .then((res) => {})
      .catch((err) => {
        this.setState({
          recommended: recommended.map((ele) => {
            if (ele.partitionKey === id) {
              return {
                ...ele,
                state: !newState,
              };
            }
            return ele;
          }),
        });
      });
  };
  render() {
    const { slides, locations, categories, highlights, products, recommended } =
      this.state;
    return (
      <>
        <div className="home-main-view">
          <HomeSlider slides={slides} />
        </div>
        <Container style={{ height: "1800px" }}>
          <HomeAdvancedSearch locations={locations} categories={categories} />
          <div style={{ transform: "translateY(-40rem)" }}>
            <Highlights highlights={highlights} />
            <FeaturedProducts
              products={products}
              handleChangeStatus={this.handleChangeFeaturedStatus}
            />
            <RecommendedProducts
              handleChangeStatus={this.handleChangeRecommendedStatus}
              products={recommended}
            />
          </div>
        </Container>
      </>
    );
  }
}

export default Home;
