import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import phoneIcon from "../../assets/phone-icon.svg";

import CustomButton from "../../components/custom-button";
import MaterialCloseIcon from "@material-ui/icons/Close";
import { createStructuredSelector } from "reselect";
import axios from "../../utils/axios";

// selectors
import { selectUserData } from "../../redux/user/selector";
import { selectWidth } from "../../redux/window-size/selector";
import CustomModal from "../custom-modal";
import CustomCard from "../custom-card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./product-details-button.scss";
import { useHistory } from "react-router";
import { Divider } from "@material-ui/core";
import LoginSignup from "../login-signup";

const ContactOwnerModal = ({ open, handleClose, user, handleOpenLogin }) => {
  const handleLogin = () => {
    handleClose();
    handleOpenLogin();
  };
  return (
    <CustomModal open={open} classes="remove-item-modal" closeModal={handleClose}>
      <CustomCard classes="remove-item-card contact-owner-modal">
        {user ? (
          <>
            <h3>Contact Owner</h3>
            <h4 style={{ marginBottom: "2px" }}>Owner</h4>
            <h6 style={{ color: "#1070c9", marginTop: 0 }}>{user?.name}</h6>
            <Divider className="divider" />
            <h4 style={{ marginBottom: "2px" }}>Phone</h4>
            <h6 style={{ color: "#1070c9", marginTop: 0 }}>{user?.phone}</h6>
          </>
        ) : (
          <>
            <h3 style={{ textAlign: "left", marginLeft: "10px" }}>Login Required</h3>
            <p style={{ marginBottom: "2px", textAlign: "left", marginLeft: "10px" }}>Please login to Contact Owner</p>
            <Divider />
            <p onClick={handleLogin} style={{ color: "#1070c9", cursor: "pointer" }}>
              Login /Register
            </p>
          </>
        )}
      </CustomCard>
    </CustomModal>
  );
};

const ProductDetailsButton = ({ width, step, user, productOwner, handleRemove, title }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [seller, setSeller] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.get(`GetSellerInfo/${productOwner}`).then(res => setSeller(res.data.result));
  }, [productOwner]);
  const [openLogin, setOpenLogin] = useState(false);
  const handleCloseLogin = () => setOpenLogin(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const mobile = width > 768;
  const owner = user?.memberId === productOwner;
  if (owner && productOwner) {
    return (
      <>
        <CustomModal open={open} classes="remove-item-modal" closeModal={handleClose}>
          <CustomCard classes="remove-item-card">
            {step === 1 ? (
              <>
                <h4>Are you sure you want to remove '{title}'</h4>
                <CustomButton
                  styles={{
                    color: "white",
                    margin: "5px",
                    padding: "10px",
                    fontSize: "17px",
                  }}
                  onClick={() => {
                    handleRemove();
                  }}>
                  Yes
                </CustomButton>
                <CustomButton
                  styles={{
                    color: "white",
                    margin: "5px",
                    padding: "10px",
                    fontSize: "17px",
                  }}
                  onClick={handleClose}>
                  No
                </CustomButton>
              </>
            ) : (
              <>
                <h4
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}>
                  <CheckCircleIcon style={{ color: "#8fbe8f", marginRight: "3px" }} /> Your item deleted successfully
                </h4>
                <CustomButton
                  styles={{
                    color: "white",
                    margin: "5px",
                    padding: "10px",
                    fontSize: "17px",
                  }}
                  onClick={() => {
                    handleClose();
                    history.push("/profile/items");
                  }}>
                  Ok
                </CustomButton>
              </>
            )}
          </CustomCard>
        </CustomModal>

        <CustomButton
          styles={{
            color: "white",
            display: "flex",
            alignItems: "center",
            padding: "10px",
          }}
          onClick={handleOpen}>
          <MaterialCloseIcon
            style={{
              color: "white",
              marginRight: mobile ? "5px" : "",
              width: "17px",
              height: "17px",
            }}
            size="large"
          />
          {mobile ? <span>Remove Item</span> : null}
        </CustomButton>
      </>
    );
  }
  return (
    <>
      <CustomModal open={openLogin} classes="login-modal" closeModal={handleCloseLogin}>
        <LoginSignup handleCloseLogin={handleCloseLogin} />
      </CustomModal>
      <CustomButton
        styles={{
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          background: "#1d1d1f",
        }}
        handleClick={handleOpen}>
        <img
          style={{
            marginRight: mobile ? "5px" : "",
            width: "17px",
            height: "17px",
          }}
          src={phoneIcon}
          alt="Contact Owner"
        />
        <span>Contact Owner</span>
      </CustomButton>
      <ContactOwnerModal handleOpenLogin={handleOpenLogin} open={open} user={seller} handleClose={handleClose} />
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUserData,
  width: selectWidth,
});

export default connect(mapStateToProps)(ProductDetailsButton);
