import React from "react";

import "./cutom-input.scss";

const CustomInput = ({
  label,
  value,
  styles,
  type,
  name,
  handleChange,
  ...props
}) => {
  return (
    <input
      className="custom-input"
      type={type}
      value={value}
      style={{ ...styles }}
      name={name}
      onChange={handleChange}
      label-area={label}
      placeholder={label}
      autocomplete="off"
      {...props}
    />
  );
};

export default CustomInput;
