import React from "react";
import Grid from "@material-ui/core/Grid";
import ProductCard from "../product-card";
import "./recommended-products.scss";

const RecommendedProducts = ({ products, handleChangeStatus }) => {
  return (
    <div className="recommended-products">
      <h1 style={{ color: "#E6E8FA" }}>RECOMMENDED FOR YOU</h1>
      {products ? (
        <Grid container spacing={3}>
          {products
            .slice(0, 2)
            .map(
              ({
                title,
                price,
                currency,
                location,
                requestVIPServices,
                media,
                state,
                status,
                partitionKey,
                attributes,
                sponsoredBy,
              }) => (
                <Grid item xs={12} md={6} lg={6}>
                  <ProductCard
                    requestVIPServices={requestVIPServices}
                    name={title}
                    price={price}
                    currency={currency}
                    prodLocation={location}
                    img={process.env.REACT_APP_API_IMAGE_URL + media[0].link}
                    mediaType={media[0]}
                    status={status}
                    state={state}
                    id={partitionKey}
                    isSponsored={sponsoredBy === "true"}
                    attributes={attributes}
                    isRecommendedProduct
                    styles={{
                      display: "inline-block",
                      width: "50%",
                      height: "300px",
                    }}
                    handleChangeStatus={handleChangeStatus}
                  />
                </Grid>
              )
            )}
        </Grid>
      ) : (
        <h3>There are no recommended yet</h3>
      )}
    </div>
  );
};
export default RecommendedProducts;
