import React from "react";

import "./contact-info.scss";
import locationImg from "../../assets/location.svg";
import mailImg from "../../assets/mail.svg";
import callImg from "../../assets/call.svg";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { BsThreadsFill } from "react-icons/bs";
const ContactInfo = () => {
  return (
    <div
      style={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/*<div className="contact-item">
        <img src={locationImg} alt="address" /> 53 Nozha street, Nasr city
      </div>
      <div className="contact-item">
        <img src={mailImg} alt="email" /> sales@connectedapp.com
      </div>
      <div className="contact-item">
        <img src={callImg} alt="mobile" /> +201027651515
      </div>*/}
      <div style={{ display: "flex", alignItems: "center", gap: "2.5rem" }}>
        <Link
          className="footer-item"
          to="/https://www.facebook.com/connectedvip/"
        >
          <FaFacebookF style={{ fontSize: "1.7rem" }} />
        </Link>
        <Link
          className="footer-item"
          to="/https://www.instagram.com/connectedvipassistant/"
        >
          <FaInstagram style={{ fontSize: "1.7rem" }} />
        </Link>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "2.5rem" }}>
        <Link
          className="footer-item"
          to="/https://www.threads.net/@connectedvipassistant"
        >
          <BsThreadsFill style={{ fontSize: "1.7rem" }} />
        </Link>
        <Link
          className="footer-item"
          to="/https://www.linkedin.com/company/connected-vip-assistant/"
        >
          <FaLinkedin style={{ fontSize: "1.7rem" }} />
        </Link>
      </div>

      <Link
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginTop: "1rem",
          padding: "6px 12px",
          borderRadius: "5px",
          backgroundColor: "#1d1d1f",
          width: "max-content",
        }}
        className="footer-item"
        to="/https://wa.me/201027651515"
      >
        <FaWhatsapp style={{ fontSize: "1.5rem" }} />
        <span>Chat on WhatsApp</span>
      </Link>
    </div>
  );
};

export default ContactInfo;
