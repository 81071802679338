import React from "react";
import axios from "../../utils/axios";
import CustomInput from "../custom-input";
import { useState } from "react";
import { Formik, Form } from "formik";
import CustomButton from "../custom-button";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";

import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { CircularProgress } from "@material-ui/core";

const Signup = ({ setStep, setType }) => {
  const [country, setCountry] = useState("EG");
  const [valid, setValid] = useState({ val: true, msg: "User not found" });
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const res = await axios.post("register", {
      ...values,
      phone: getCountryCallingCode(country) + values.mobile,
    });
    if (!res.data.success) {
      setValid({ val: false, msg: "User not found" });
    } else {
      setValid({ val: true });
      setStep(2);
    }
    setIsLoading(false);
    setSubmitting(false);
  };
  return (
    <>
      <h2>Join Now</h2>
      <Formik
        initialValues={{ mobile: undefined, name: "", email: undefined }}
        validate={(values) => {
          setValid({ val: true });
          const errors = {};
          if (!values.name.trim()) {
            errors.name = "Name is required";
          }
          if (!values.mobile) {
            errors.mobile = "Mobile is required";
          } else if (
            !isValidPhoneNumber(
              "+" + getCountryCallingCode(country) + values.mobile
            ) ||
            !isPossiblePhoneNumber(
              "+" + getCountryCallingCode(country) + values.mobile
            ) ||
            values.mobile.toString().length < 10
          ) {
            errors.mobile = "Mobile is is wrong";
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          handleChange,
        }) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <span className="error-msg">
              {errors.name && touched.name && errors.name}
            </span>
            <div className="login-form-item">
              <CustomInput
                label="Enter your name *"
                type="text"
                name="name"
                value={values.name}
                handleChange={handleChange}
              />
            </div>

            <span className="error-msg">
              {errors.mobile && touched.mobile && errors.mobile}
            </span>
            <span className="error-msg">
              {!valid.val && !errors.mobile && valid.msg}
            </span>
            <div className="login-form-item">
              <select
                value={country}
                name="country"
                onChange={(event) =>
                  setCountry(event.target.value || undefined)
                }
              >
                {getCountries().map((country) => (
                  <option key={country} value={country}>
                    {getUnicodeFlagIcon(country)} {country} +
                    {getCountryCallingCode(country)}
                  </option>
                ))}
              </select>
              <CustomInput
                label="Enter your mobile *"
                type="number"
                name="mobile"
                value={values.mobile}
                handleChange={handleChange}
              />
            </div>
            <span className="error-msg">
              {errors.email && touched.email && errors.email}
            </span>
            <div className="login-form-item">
              <CustomInput
                label="Enter your email"
                type="email"
                name="email"
                value={values.email}
                handleChange={handleChange}
              />
            </div>
            <CustomButton
              styles={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "235px",
                height: "48px",
                color: "white",
              }}
              type="submit"
              disabled={isSubmitting}
            >
              <div>Register</div>
              {isLoading && <CircularProgress size={20} />}
            </CustomButton>
            <div
              className="user-part"
              onClick={() => {
                setType(1);
              }}
            >
              Login
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Signup;
