import React from "react";
import { Link } from "react-router-dom";

import "./header-category.scss";

const HeaderCategory = ({ img, title, slogan, link }) => {
  return (
    <div className="header-category">
      {/*      <div>
        <Link to={link}>
          <h1 style={{ fontFamily: "FuturaPTLight", color: "#E6E8FA" }}>
            {slogan.toUpperCase()}
          </h1>
        </Link>
      </div> */}
      <Link style={{ display: "inline-block", width: "100%" }} to={link}>
        <video
          class="slider-image hoverZoomLink"
          loop=""
          style={{ width: "100%" }}
          muted
          playsinline=""
          autoplay=""
          __idm_id__="188417"
        >
          <source src={img} type="video/mp4" />
        </video>
      </Link>
    </div>
  );
};

export default HeaderCategory;
