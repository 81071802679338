import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";

import axios from "../../utils/axios";
import logo from "../../assets/logo2.png";
const DealsPage = ({ match: { params } }) => {
  const [ad, setAd] = useState(undefined);
  // member/GetAdById/
  const { id } = params;
  useEffect(() => {
    axios.get(`GetAdById/${id}`).then(async res => {
      const product = res.data.result;
      console.log("Errrr", product);
      setAd(product);
    });
  }, [id]);
  if (!ad) return <div>Loading...</div>;
  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-around", marginTop: "60px" }}
        className="deals-container">
        <div style={{ maxWidth: "45%" }}>
          <img style={{ height: "92px" }} alt="Connected" src={logo} />
        </div>
        <Divider style={{ height: "92px", background: "white" }} orientation="vertical" />
        <img style={{ height: "92px" }} alt="Ad logo" src={process.env.REACT_APP_API_IMAGE_URL + ad?.advertiserLogo} />
      </div>
      <div style={{ color: "white", textAlign: "center", fontSize: "25px", fontWeight: "bold", marginTop: "20px" }}>
        Request Received
      </div>
      <div style={{ color: "white", textAlign: "center", fontSize: "25px", fontWeight: "bold", marginTop: "20px" }}>
        An Expert Will Contact You...
      </div>
    </div>
  );
};

export default DealsPage;
