import React from "react";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import HomeSliderController from "../home-slider-controller";

import "./home-slider.scss";
import HeaderCategory from "../header-category";

import lifestyleImg from "../../assets/life-style.png";
import realstateImg from "../../assets/real-state.png";
import automotiveImg from "../../assets/automotive3.png";
import DownloadApp from "../DownloadApp";
import Header from "../header";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const HomeSlider = ({ slides }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [pause, setPause] = React.useState(true);
  const maxSteps = 4;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePasue = () => {
    setPause((state) => !state);
  };
  return (
    <div className="slider-container">
      <Header />

      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        style={{ transform: "translateY(-16rem)" }}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={!pause}
      >
        {/*<DownloadApp />*/}
        <div style={{ position: "relative" }}>
          <div className="overlay-slider"></div>
          <HeaderCategory
            img={
              "https://www.connectedapp.com/static/media/banner_1.a2c1b47f.mp4"
            }
            title="REAL ESTATE"
            slogan="Dream Home"
            link="/real-estate"
          />
        </div>

        <div style={{ position: "relative" }}>
          <div className="overlay-slider"></div>
          <HeaderCategory
            img={
              "https://www.connectedapp.com/static/media/banner_3.c19f49b3.mp4"
            }
            title="Automotive"
            slogan="Premium automotive"
            link="/automotive"
          />
        </div>

        <div style={{ position: "relative" }}>
          <div className="overlay-slider"></div>
          <HeaderCategory
            img={
              "https://www.connectedapp.com/static/media/banner_2.abcbaa11.mp4"
            }
            title="LIFE STYLE"
            slogan="Luxury Lifestyle"
            link="/life-style"
          />
        </div>
        {/* {slides.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                style={{ width: "100%", height: "523px" }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))} */}
      </AutoPlaySwipeableViews>
      <HomeSliderController
        handleBack={handleBack}
        handleNext={handleNext}
        handlePasue={handlePasue}
        maxSteps={maxSteps}
        activeStep={activeStep}
        pause={pause}
      />
    </div>
  );
};

export default HomeSlider;
