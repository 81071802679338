import types from "./types";

export const user_login = (user) => {
  return {
    type: types.USER_LOGIN,
    payload: user,
  };
};

export const user_logout = () => ({
  type: types.USER_LOGOUT,
});
