import React from "react";
import "./product-button.scss";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const ShowPriceButton = ({ children, handleClick, id, status, item: { name, price, currency } }) => {
  const handleButtonClick = () => {
    handleClick();
  };
  return (
    <div className="product-button-container" style={{marginRight: '5px'}}>
      <button className="product-button" onClick={handleButtonClick}>
        <span className="heart-circle" style={{color:'black'}}>
          <VisibilityOutlinedIcon />
        </span>{" "}
        {children}
      </button>
    </div>
  );
};

export default ShowPriceButton;
