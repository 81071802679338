import React from "react";
import PrevPageLinks from "../../components/prev-page-links";
import { Link } from "react-router-dom";

import "./page-not-found.scss";
const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <PrevPageLinks>
        <Link className="prev" to="/">
          Home
        </Link>{" "}
        /{" "}
        <Link className="current" to="/404-notfound">
          Page Not Found
        </Link>
      </PrevPageLinks>
      <h2>404 NOT FOUND</h2>
    </div>
  );
};

export default PageNotFound;
