import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./profile-items.scss";
import ProductCard from "../product-card";
import { Box, Grid } from "@material-ui/core";
import axios from "../../utils/axios";
import { Skeleton } from "@material-ui/lab";

const ProfileItems = ({ match, width }) => {
  const [products, setProducts] = useState([null, null, null, null]);
  const handleRemoveItem = id => {
    setProducts(products => products.filter(ele => ele.id !== id));
  };
  const mobile = width <= 768;
  const spacing = mobile ? 0 : 3;
  const [locations, setLocations] = useState([]);
  const [firstTime, setFirstTime] = useState(true);

  const getLocations = async () => {
    const res = await axios.get("GetLocations");
    setLocations(res.data.result);
  };
  useEffect(() => {
    if (firstTime) {
      getLocations();
      setFirstTime(false);
    }
    if (locations)
      axios.post("GetAddedItems").then(res => {
        const items = res.data.result.items.map(ele => {
          const locName = locations.find(loc => loc.partitionKey === ele?.location)?.name;
          if (ele.attributes) {
            const attr = Object.entries(ele?.attributes)?.map(ele => ({
              [ele[0]]: ele[1],
            }));
            ele.attributes = attr;
          }
          ele.location = locName;
          return ele;
        });
        setProducts(items);
      });
  }, [locations]);
  return (
    <div className="profile-items">
      <Grid container spacing={spacing} style={{ margin: 0 }}>
        {products.map(item => (
          <Grid
            key={item?.partitionKey}
            item
            xs={12}
            md={6}
            lg={6}
            style={{
              position: "relative",
              paddingTop: `${mobile ? "10px" : ""}`,
            }}>
            {item ? (
              <ProductCard
                profileItem
                requestVIPServices={item.requestVIPServices}
                currency={item.currency}
                name={item.title}
                price={item.price}
                prodLocation={item.location}
                img={process.env.REACT_APP_API_IMAGE_URL + item.media[0].link}
                mediaType={item.media[0]}
                status={item.status}
                id={item.partitionKey}
                attributes={item.attributes}
                isSponsored={item.sponsoredBy === "true"}
                styles={{
                  display: "inline-block",
                  width: "50%",
                  height: "254px",
                }}
                handleRemoveItem={() => {}}
              />
            ) : (
              <Box pt={0.5}>
                <Skeleton variant="rect" height={254} />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ windowReducer: { width } }) => ({
  width,
});
export default connect(mapStateToProps)(ProfileItems);
