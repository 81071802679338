import React from "react";
import axios from "../../utils/axios";
import CustomInput from "../custom-input";
import ReactCodeInput from "react-verification-code-input";

import { useState } from "react";
import { Formik, Form } from "formik";
import CustomButton from "../custom-button";

import { CircularProgress } from "@material-ui/core";
import { user_login } from "../../redux/user/actions";
import { useDispatch } from "react-redux";
const Verify = ({ number, settings }) => {
  const [valid, setValid] = useState({ val: true, msg: "Code is wrong" });
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState(null);
  const dispatch = useDispatch();
  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const url = settings ? "verifyChangePhone" : "verify";
    const res = await axios.post(url, {
      Phone: number,
      pass: code,
    });
    if (!res.data.success) {
      setValid({ val: false, msg: "Code is wrong" });
    } else {
      if (!settings) dispatch(user_login(res.data.result));
      setValid({ val: true });
      // window.location.reload();
    }
    setIsLoading(false);
    setSubmitting(false);
  };
  return (
    <>
      <h2>VERIFY</h2>
      <Formik
        initialValues={{ code: "" }}
        validate={(values) => {
          setValid({ val: true });
          const errors = {};
          if (!code) {
            errors.mobile = "Code is required";
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <span className="error-msg">
              {errors.code && touched.code && errors.code}
            </span>
            <span className="error-msg">
              {!valid.val && !errors.code && valid.msg}
            </span>
            <div className="login-form-item">
              <ReactCodeInput
                className="code-input"
                fields={4}
                autoFocus={true}
                onChange={(e) => {
                  setCode(e);
                }}
                name="code"
              />
            </div>
            <CustomButton
              styles={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "235px",
                height: "48px",
                color: "white",
              }}
              type="submit"
              disabled={isSubmitting}
            >
              <div>Verify</div>
              {isLoading && <CircularProgress size={20} />}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Verify;
