import React, { useEffect, useState } from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import "./product-details.scss";
import PrevPageLinks from "../../components/prev-page-links";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  Divider,
  List,
  ListItemText,
  ListItem,
  Box,
  Container,
} from "@material-ui/core";
import ProductDetailsSlider from "../../components/product-details-slider";
import ProductDetailsButton from "../../components/product-details-button";
import ProductLocation from "../../components/product-location";
import ProductPriceCard from "../../components/product-price-card";
import ProductAdsCard from "../../components/product-ads-card";
import axios from "../../utils/axios";
import { Skeleton } from "@material-ui/lab";
import CustomModal from "../../components/custom-modal";
import CustomCard from "../../components/custom-card";
import CustomButton from "../../components/custom-button";
import ProductPriceButton from "../../components/product-price-button";
import Header from "../../components/header";

const ProductDetails = ({ match: { params } }) => {
  const [product, setProduct] = useState(null);
  const [ads, setAds] = useState([]);
  const [done, setDone] = useState(false);
  const [step, setStep] = useState(1);
  const handleClick = () => {
    const { id } = params;
    const curr = !product.state;
    setProduct({ ...product, state: curr });
    const type = curr ? "AddItemToWishlist" : "RemoveItemFromWishlist";
    axios
      .post(type, { itemId: id })
      .then((res) => {})
      .catch((err) => {
        setProduct({ ...product, state: !curr });
      });
  };
  const handleRemove = () => {
    const { id } = params;
    axios.post("RemoveItem", { itemId: id }).then((res) => {
      setStep(2);
    });
  };
  useEffect(() => {
    //get the data
    const { id } = params;
    axios.post("GetConcierge").then((res) => {
      setAds(res.data.result);
    });
    axios
      .post("GetSpecificItem", {
        itemId: id,
      })
      .then(async (res) => {
        const product = res.data.result;
        const locations = await axios.get("GetLocations");
        const wishlist = await (
          await axios.post("GetWishlistedItems")
        )?.data?.result?.items;
        const existEle = wishlist?.find(
          (item) => item.partitionKey === product.itemId
        );
        product.state = existEle ? true : false;
        const loc = locations.data.result.find(
          (ele) => ele.partitionKey === product.location
        ).name;
        const attr = Object.entries(product.attributes).map((ele) => ({
          [ele[0]]: ele[1],
        }));
        product.attributes = attr;
        product.location = loc;
        setProduct(product);
      });
  }, []);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBuy = () => {
    const { id } = params;
    setDone(true);
    axios.post("BuyItem", { itemId: id });
  };
  const history = useHistory();
  return (
    <div>
      <Header />
      <Container>
        <div className="product-details-container">
          <CustomModal classes="buy-modal" closeModal={handleClose} open={open}>
            <CustomCard classes="buy-card">
              {!done ? (
                <>
                  {" "}
                  <h1>BUY NOW</h1>
                  <h3>
                    By pressing confirm you agree to our{" "}
                    <Link to="/terms-conditions" target="_blank">
                      Terms & Conditions
                    </Link>
                  </h3>
                  <CustomButton
                    type="button"
                    styles={{
                      display: "block",
                      height: "40px",
                      width: "150px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                    onClick={handleBuy}
                  >
                    Confirm
                  </CustomButton>{" "}
                </>
              ) : (
                <>
                  <h1>Congrates</h1>
                  <h3>Connected VIP Assistants will contact you soon</h3>{" "}
                  <CustomButton
                    type="button"
                    styles={{
                      display: "block",
                      height: "40px",
                      width: "150px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                    onClick={handleClose}
                  >
                    Close
                  </CustomButton>
                </>
              )}
            </CustomCard>
          </CustomModal>
          {product ? (
            <PrevPageLinks>
              <Link className="prev" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="current" to={"/products" + product?.id}>
                {product?.title}
              </Link>
            </PrevPageLinks>
          ) : (
            <Box pt={0.5}>
              <Skeleton width="60%" />
            </Box>
          )}
          <Grid container spacing={3} style={{ marginTop: "46px" }}>
            <Grid item lg={12}>
              {product ? (
                <>
                  <ProductDetailsSlider slides={product.media} />
                  <div className="product-details-header">
                    <div>
                      <h3>
                        {product.title}{" "}
                        {product.requestVIPServices ? (
                          <VerifiedUserIcon
                            fontSize="small"
                            style={{ color: "#1070C9", marginLeft: "5px" }}
                          />
                        ) : null}
                      </h3>
                      {!product.isSponsored && (
                        <ProductLocation
                          productDetails={true}
                          prodLocation={product.location}
                        />
                      )}
                    </div>
                    <div>
                      <ProductPriceButton
                        step={step}
                        title={product.title}
                        handleRemove={handleRemove}
                        productOwner={product.itemOwner}
                        status={product.state}
                        handleClick={handleClick}
                        currency={product?.currency}
                        price={product?.price}
                        isSponsored={product.isSponsored}
                      />
                      {!product.isSponsored && (
                        <ProductDetailsButton
                          step={step}
                          title={product.title}
                          handleRemove={handleRemove}
                          productOwner={product.itemOwner}
                          status={product.state}
                          handleClick={handleClick}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <Box pt={0.5}>
                  <Skeleton variant="rect" height={296} />
                  <Skeleton width="60%" />
                  <Skeleton width="60%" />
                </Box>
              )}
            </Grid>

            <Grid item lg={8} xs={12} md={8}>
              {product ? (
                <>
                  <div className="product-details-description">
                    <h4>Description</h4>
                    <p style={{ maxWidth: "443px" }}>{product.description}</p>
                  </div>
                  <Divider className="divider" />
                  <div className="product-details-description">
                    <h4>More info</h4>
                    <List
                      style={{ maxWidth: "743px" }}
                      component="nav"
                      aria-label="main mailbox folders"
                    >
                      <Grid container spacing={2}>
                        {product.attributes.map((attr) => (
                          <Grid item lg={12} md={12} xs={12}>
                            <ListItem style={{ paddingLeft: "0" }}>
                              <ListItemText
                                className="attribute-key"
                                primary={Object.keys(attr)}
                              />
                              <ListItemText
                                className="attribute-value"
                                primary={Object.values(attr)}
                              />
                            </ListItem>
                          </Grid>
                        ))}
                      </Grid>
                    </List>
                  </div>
                </>
              ) : null}
            </Grid>
            <Grid className="product-detailas-cards" item lg={4} xs={12} md={4}>
              {product ? (
                <>{ads.length ? <ProductAdsCard ads={ads} /> : null}</>
              ) : (
                <Box pt={0.5}>
                  <Skeleton variant="rect" height={196} />
                  <br />
                  <Skeleton variant="rect" height={196} />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ProductDetails;
