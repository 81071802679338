import React from "react";

import SwipeableViews from "react-swipeable-views";
// import Button from "@material-ui/core/Button";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Pagination from "./pagination";
import "./product-details-slider.scss";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];
const AutoPlaySwipeableViews = SwipeableViews;

const ProductDetailsSlider = ({ slides }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };

  return (
    <div className="product-slider-container">
      <ImageGallery
        showPlayButton={false}
        showThumbnails={false}
        showNav={false}
        showBullets={true}
        originalClass='small-img'
        items={slides.map((ele) => ({
          original: process.env.REACT_APP_API_IMAGE_URL + ele.link,
          thumbnail: process.env.REACT_APP_API_IMAGE_URL + ele.link,
        }))}
      />
      {/* <AutoPlaySwipeableViews
        axis="x"
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
      >
        {slides.map((step, index) => (
          <div key={step.link}>
            <img
              style={{ width: "100%", height: "320px", borderRadius: "15px" }}
              src={process.env.REACT_APP_API_IMAGE_URL + step.link}
              alt={step.type}
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div className="dots-container">
        <Pagination
          steps={slides}
          index={index}
          handleChange={handleChangeIndex}
        />
      </div> */}
      {/* <div className="product-slider-controller">
        <Button
          onClick={handleBack}
          className={activeStep === 0 ? "disabled-icon" : ""}
          disabled={activeStep === 0}
        >
          <KeyboardArrowLeft className="product-slider-icons" />
        </Button>
        <Button
          onClick={handleNext}
          className={activeStep === maxSteps - 1 ? "disabled-icon" : ""}
          disabled={activeStep === maxSteps - 1}
        >
          <KeyboardArrowRight className="product-slider-icons" />
        </Button>
      </div> */}
    </div>
  );
};

export default ProductDetailsSlider;
