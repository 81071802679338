import React from "react";

import "./footer.scss";
import { Grid, Divider } from "@material-ui/core";

import { Link } from "react-router-dom";
import AppStore from "../../assets/app-store.svg";
import PlayStore from "../../assets/play-store.svg";

import ContactInfo from "../contact-info";
import ContactFrom from "../contact-form";
import cataloguePDF from "../../assets/catalogue.pdf";

const Footer = () => {
  return (
    <div className="pages-layout" id="getTheApp">
      <div className="footer-container">
        <Divider className="divider" />
        <Grid className="grid-container" container spacing={3}>
          <Grid
            item
            lg={3}
            xs={12}
            sm={6}
            md={6}
            style={{ textAlign: "center" }}
          >
            <h3>GET THE APP</h3>

            <Link className="footer-item" to="/faq">
              Frequently Asked Questions
            </Link>
            <Link className="footer-item" to="/contact-us">
              Need Help?
            </Link>
            <Link className="footer-item" to="/terms-conditions">
              Terms & conditions
            </Link>
            {/* <Link className="footer-item" to="/concierge">
          Premium Advertisement
        </Link>
        <Link className="footer-item" to="/about-us">
          About Us
        </Link>
        <Link className="footer-item" to="/contact-us">
          Contact Us
        </Link>
        <Link className="footer-item" to="/terms-conditions">
          Terms & Conditions
        </Link> */}
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            sm={6}
            md={6}
            style={{ textAlign: "center" }}
          >
            <h3>Blog & news</h3>

            <Link className="footer-item" target="_blank" to={cataloguePDF}>
              April Catalogue
            </Link>
          </Grid>
          {/*<Grid item lg={3} xs={12} sm={6} md={6}>
        <h3>GET THE APP</h3>
        <p>
          Connected App is now available on App Store & Google play. Get it
          now.
        </p>
        <div className="get-app-container">
          <a
            className="get-app"
            target="_blank"
            href="https://apps.apple.com/us/app/id1482090673"
          >
            <img src={AppStore} alt="app store" />
          </a>
          <a
            className="get-app"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.connected.app"
          >
            <img src={PlayStore} alt="play store" />
          </a>
        </div>
      </Grid> */}
          <Grid item lg={3} xs={12} sm={6} md={6}>
            <h3 style={{ textAlign: "center" }}>social media</h3>
            <ContactInfo />
          </Grid>
          <Grid item lg={3} xs={12} sm={6} md={6}>
            <h3 style={{ textAlign: "center" }}>Get The app</h3>
            <ContactFrom />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Footer;
